import { isShallowEqual } from 'common/IsShallowEqual';
import { StorageKeys } from 'common/storage/constants';
import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { IStorage } from 'src/shared/storage/Storage';

export interface IStorageOptions {
  /**
   * The key to store data under in storage
   */
  key: StorageKeys;

  /**
   * The storage mechanism to use, e.g. LocalStorage, SessionStorage etc
   */
  store: IStorage;

  /**
   * A number which identifies the version of the data being stored, or the version expected
   * to be read from storage. If a persisted state is read that has a different version than
   * the version expected, the persisted state is cleared and not used.
   */
  version: number;

  /**
   * The timestamp of when this stored state is no longer valid. If not provided, the state
   * is valid indefinitely.
   */
  expiresAt?: number;
}

export interface IScopedReducerOptions {
  storage: IStorageOptions;
}

export interface IPersistedState<T> {
  /**
   * The a version number for `state`. Upon reading the persisted state, if this version does
   * not match up with an expected version number, the persisted state is cleared and not used.
   */
  version: number;

  /**
   * The timestamp of when this stored state is no longer valid. If not provided, the state
   * is valid indefinitely.
   */
  expiresAt?: number;

  /**
   * The actual data to store
   */
  state: T;
}

export type Reducer<T> = (reducer: (prevSlice: T) => T) => void;

/**
 * This should only be called one time per app.  It creates a React context provider, as
 * well as a hook which can be used to make modules that depend on global state.
 *
 * If you are adding new, Shef-related logic, you probably just need to modify the code
 * in {@link shef-web/shef-global-state/shefGlobalState}.
 *
 * @param initialState the initial state of the world
 */
export function createGlobalState<S extends { [key: string]: any }>(initialState: S) {
  type AppContextType = [Readonly<S>, (reduce: (state: S) => S) => void];
  const GlobalStateContext = createContext<AppContextType>([initialState, () => undefined]);

  const GlobalStateProvider: React.FC = ({ children }) => {
    const [globalState, setState] = useState(initialState);

    // Keep a synchronously flushed state locally so that subsequent calls to globalReduce
    // within a single JS tick get the most up-to-date global state, without waiting for
    // react to asynchronously flush setState changes
    const currentStateRef = useRef<S>(globalState);

    const globalReduce = useCallback((reducer: (prevState: S) => S) => {
      const prevState = currentStateRef.current;
      currentStateRef.current = reducer(currentStateRef.current);
      if (!isShallowEqual(prevState, currentStateRef.current)) {
        setState(currentStateRef.current);
      }
    }, []);

    return <GlobalStateContext.Provider value={[globalState, globalReduce]}>{children}</GlobalStateContext.Provider>;
  };

  const useScopedReducer = <K extends keyof S>(key: K, options: Partial<IScopedReducerOptions> = {}) => {
    const [globalState, globalReduce] = useContext(GlobalStateContext);
    const state = globalState[key];

    const reduce: Reducer<S[K]> = useCallback(
      (reducer: (prevSlice: S[K]) => S[K]) => {
        globalReduce((state) => {
          const next = reducer(state[key]);
          if (isShallowEqual(next, state[key])) {
            return state;
          }

          if (options.storage) {
            const { store, key, version, expiresAt } = options.storage;
            const persistedState: IPersistedState<S[K]> = {
              version,
              expiresAt,
              state: next,
            };
            store.setItem(key, JSON.stringify(persistedState));
          }

          return {
            ...state,
            [key]: next,
          };
        });
      },
      [globalReduce, key, options.storage]
    );

    return {
      state,
      reduce,
      globalState,
      globalReduce,
    };
  };

  const useGlobalReducer = () => {
    const [state, reduce] = useContext(GlobalStateContext);
    return {
      state,
      reduce,
    };
  };

  return { useScopedReducer, useGlobalReducer, GlobalStateProvider };
}

/**
 * Loads a persisted state from storage. If provided, `options` should match the `options` that
 * were originally passed to `useScopedReducer`.
 */
export function loadPersistedState<S>(options: IStorageOptions): S | null {
  const { store, key, version } = options;
  const stored = store.getItem(key);
  if (!stored) {
    return null;
  }

  const clearPersistedData = () => {
    store.removeItem(key);
    return null;
  };

  try {
    const persisted: IPersistedState<S> = JSON.parse(stored);
    const expired = persisted.expiresAt ? Date.now() > persisted.expiresAt : false;
    if (persisted.version === version && !expired) {
      return persisted.state;
    }
    return clearPersistedData();
  } catch (err) {
    return clearPersistedData();
  }
}
