import { initialShefProfileState } from './initialState';
import {
  ChangeBioAction,
  ChangeBusinessNameAction,
  ChangeCoverPhotoAction,
  ChangeCuisineCategoryIdsAction,
  ChangeDropoffHubAction,
  ChangeEmailAction,
  ChangeFacebookHandleAction,
  ChangeFirstNameAction,
  ChangeHasSubmittedPhotosActions,
  ChangeInstagramHandleAction,
  ChangeLastNameAction,
  ChangeLinkedInHandleAction,
  ChangeOrderCancelationAction,
  ChangeOrderCapacityAction,
  ChangeOrderCutoffTimeAction,
  ChangeOrderNotificationAction,
  ChangePhoneAction,
  ChangePhotoOptionA,
  ChangePhotoOptionAll,
  ChangePhotoOptionB,
  ChangePhotoOptionC,
  ChangePreviewImageAction,
  ChangeTikTokHandleAction,
  ChangeTwitterHandleAction,
  ChangeWebsiteUrlAction,
  ChangeYoutubeHandleAction,
  LoadProfileAction,
  SaveProfileAction,
  ShefProfileState,
} from './types';
import { getUpdatedInputState } from './utils';

export const loadShefProfile = (state: ShefProfileState, { payload }: LoadProfileAction) => {
  const { isEditable, shefApplication, shefProfile } = payload;
  const {
    bio,
    coverPhotoUrl,
    cancelOrderNotificationFrequency,
    cutoffMsBeforeDeliveryDate,
    cuisineCategoryIds,
    defaultDropoffHub = {},
    id,
    newOrderNotificationFrequency,
    dishCapacity,
    maxDishCapacity,
    phone,
    businessName,
    websiteUrl,
    facebookHandle,
    instagramHandle,
    twitterHandle,
    tikTokHandle,
    linkedInHandle,
    youtubeHandle,
    previewFoodItemId,
    user,
  } = shefProfile;
  const { profilePhotoOptionA, profilePhotoOptionB, profilePhotoOptionC } = shefApplication ?? {};
  const { firstName, lastName, email } = user;

  const loadedProfile = {
    bio,
    cancelOrderNotificationFrequency,
    coverPhotoUrl,
    cutoffMsBeforeDeliveryDate,
    cuisineCategoryIds,
    defaultDropoffHubId: defaultDropoffHub?.id,
    firstName,
    email,
    id,
    lastName,
    maxDishCapacity,
    newOrderNotificationFrequency,
    dishCapacity,
    previewFoodItemId,
    profilePhotoOptionA: profilePhotoOptionA ?? state.profilePhotoOptionA,
    profilePhotoOptionB: profilePhotoOptionB ?? state.profilePhotoOptionB,
    profilePhotoOptionC: profilePhotoOptionC ?? state.profilePhotoOptionC,
    phone,
    businessName,
    websiteUrl,
    facebookHandle,
    instagramHandle,
    twitterHandle,
    tikTokHandle,
    linkedInHandle,
    youtubeHandle,
  };

  return {
    ...state,
    ...loadedProfile,
    inputState: isEditable ? loadedProfile : {},
  };
};

export const saveProfile = (state: ShefProfileState, { payload }: SaveProfileAction) => {
  const { inputState } = state;
  const { shefProfile } = payload;
  const updatedValues = shefProfile
    ? {
        bio: shefProfile.bio,
        cancelOrderNotificationFrequency: shefProfile.cancelOrderNotificationFrequency,
        coverPhotoUrl: shefProfile.coverPhotoUrl,
        cutoffMsBeforeDeliveryDate: shefProfile.cutoffMsBeforeDeliveryDate,
        defaultDropoffHubId: shefProfile.defaultDropoffHubId,
        cuisineCategoryIds: shefProfile.cuisineCategoryIds,
        firstName: shefProfile.firstName,
        email: shefProfile.email,
        id: shefProfile.id,
        lastName: shefProfile.lastName,
        newOrderNotificationFrequency: shefProfile.newOrderNotificationFrequency,
        dishCapacity: shefProfile.dishCapacity,
        previewFoodItemId: shefProfile.previewFoodItemId,
        profilePhotoOptionA: shefProfile.profilePhotoOptionA,
        profilePhotoOptionB: shefProfile.profilePhotoOptionB,
        profilePhotoOptionC: shefProfile.profilePhotoOptionC,
        phone: shefProfile.phone,
        businessName: shefProfile.businessName,
        websiteUrl: shefProfile.websiteUrl,
        facebookHandle: shefProfile.facebookHandle,
        instagramHandle: shefProfile.instagramHandle,
        twitterHandle: shefProfile.twitterHandle,
        tikTokHandle: shefProfile.tikTokHandle,
        linkedInHandle: shefProfile.linkedInHandle,
        youtubeHandle: shefProfile.youtubeHandle,
      }
    : inputState;
  return {
    ...state,
    ...updatedValues,
    inputState: shefProfile ? updatedValues : inputState,
  };
};

export const changeBio = (state: ShefProfileState, { payload }: ChangeBioAction) => {
  return getUpdatedInputState(state, { bio: payload.bio });
};

export const changeCoverPhoto = (state: ShefProfileState, { payload }: ChangeCoverPhotoAction) => {
  return getUpdatedInputState(state, { coverPhotoUrl: payload.coverPhotoUrl });
};

export const changeCuisineCategoryIds = (state: ShefProfileState, { payload }: ChangeCuisineCategoryIdsAction) => {
  return getUpdatedInputState(state, { cuisineCategoryIds: payload.cuisineCategoryIds });
};

export const changeDropoffHub = (state: ShefProfileState, { payload }: ChangeDropoffHubAction) => {
  return getUpdatedInputState(state, { defaultDropoffHubId: payload.defaultDropoffHubId });
};

export const changeEmail = (state: ShefProfileState, { payload }: ChangeEmailAction) => {
  return getUpdatedInputState(state, { email: payload.email });
};

export const changeFirstName = (state: ShefProfileState, { payload }: ChangeFirstNameAction) => {
  return getUpdatedInputState(state, { firstName: payload.firstName });
};

export const changeLastName = (state: ShefProfileState, { payload }: ChangeLastNameAction) => {
  return getUpdatedInputState(state, { lastName: payload.lastName });
};

export const changeOrderCancelation = (state: ShefProfileState, { payload }: ChangeOrderCancelationAction) => {
  return getUpdatedInputState(state, { cancelOrderNotificationFrequency: payload.cancelOrderNotificationFrequency });
};

export const changeOrderCapacity = (state: ShefProfileState, { payload }: ChangeOrderCapacityAction) => {
  return getUpdatedInputState(state, { dishCapacity: payload.dishCapacity });
};

export const changeOrderCutoffTime = (state: ShefProfileState, { payload }: ChangeOrderCutoffTimeAction) => {
  return getUpdatedInputState(state, { cutoffMsBeforeDeliveryDate: payload.cutoffMsBeforeDeliveryDate });
};

export const changeOrderNotification = (state: ShefProfileState, { payload }: ChangeOrderNotificationAction) => {
  return getUpdatedInputState(state, { newOrderNotificationFrequency: payload.newOrderNotificationFrequency });
};

export const changePhone = (state: ShefProfileState, { payload }: ChangePhoneAction) => {
  return getUpdatedInputState(state, { phone: payload.phone });
};

export const changeBusinessName = (state: ShefProfileState, { payload }: ChangeBusinessNameAction) => {
  return getUpdatedInputState(state, { businessName: payload.businessName });
};

export const changeWebsiteUrl = (state: ShefProfileState, { payload }: ChangeWebsiteUrlAction) => {
  return getUpdatedInputState(state, { websiteUrl: payload.websiteUrl });
};

export const changeFacebookHandle = (state: ShefProfileState, { payload }: ChangeFacebookHandleAction) => {
  return getUpdatedInputState(state, { facebookHandle: payload.facebookHandle });
};

export const changeInstagramHandle = (state: ShefProfileState, { payload }: ChangeInstagramHandleAction) => {
  return getUpdatedInputState(state, { instagramHandle: payload.instagramHandle });
};

export const changeTwitterHandle = (state: ShefProfileState, { payload }: ChangeTwitterHandleAction) => {
  return getUpdatedInputState(state, { twitterHandle: payload.twitterHandle });
};

export const changeTikTokHandle = (state: ShefProfileState, { payload }: ChangeTikTokHandleAction) => {
  return getUpdatedInputState(state, { tikTokHandle: payload.tikTokHandle });
};

export const changeLinkedInHandle = (state: ShefProfileState, { payload }: ChangeLinkedInHandleAction) => {
  return getUpdatedInputState(state, { linkedInHandle: payload.linkedInHandle });
};
export const changeYoutubeHandle = (state: ShefProfileState, { payload }: ChangeYoutubeHandleAction) => {
  return getUpdatedInputState(state, { youtubeHandle: payload.youtubeHandle });
};
export const changePhotoOptionA = (state: ShefProfileState, { payload }: ChangePhotoOptionA) => {
  return getUpdatedInputState(state, { profilePhotoOptionA: payload.profilePhotoOptionA });
};

export const changePhotoOptionB = (state: ShefProfileState, { payload }: ChangePhotoOptionB) => {
  return getUpdatedInputState(state, { profilePhotoOptionB: payload.profilePhotoOptionB });
};

export const changePhotoOptionC = (state: ShefProfileState, { payload }: ChangePhotoOptionC) => {
  return getUpdatedInputState(state, { profilePhotoOptionC: payload.profilePhotoOptionC });
};

export const changePhotoOptionAll = (state: ShefProfileState, { payload }: ChangePhotoOptionAll) => {
  return getUpdatedInputState(state, {
    profilePhotoOptionA: payload.profilePhotoOptionA ?? state.profilePhotoOptionA,
    profilePhotoOptionB: payload.profilePhotoOptionB ?? state.profilePhotoOptionB,
    profilePhotoOptionC: payload.profilePhotoOptionC ?? state.profilePhotoOptionC,
  });
};

export const changePreviewImage = (state: ShefProfileState, { payload }: ChangePreviewImageAction) => {
  return getUpdatedInputState(state, { previewFoodItemId: payload.previewFoodItemId });
};

export const changeHasSubmittedPhotos = (state: ShefProfileState, { payload }: ChangeHasSubmittedPhotosActions) => {
  return { ...state, hasSubmittedPhotos: payload.hasSubmittedPhotos };
};

export const clearInputState = (state: ShefProfileState) => {
  return { ...state, inputState: {} };
};

export const resetState = () => {
  return initialShefProfileState;
};
