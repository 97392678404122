import { backoffRetry } from 'common/PromiseUtils';
import React, { ComponentType } from 'react';

export type LazyComponent<T> = React.LazyExoticComponent<React.ComponentType<T>> & {
  preload?: () => Promise<{ default: ComponentType<T> }>;
};
export const lazyRetry = <T>(
  fn: () => Promise<{ default: ComponentType<T> }>,
  attempts = 3,
  interval = 250
): LazyComponent<T> => {
  const preload = () => backoffRetry(fn, attempts, interval);
  // eslint-disable-next-line no-restricted-properties
  const Component: LazyComponent<T> = React.lazy(preload);
  Component.preload = preload;
  return Component;
};

export const isLazyComponent = <T>(x: any): x is LazyComponent<T> => {
  return x && x.preload && x.preload.apply;
};
