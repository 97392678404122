import { createSlice } from '@reduxjs/toolkit';
import { initialNavbarState } from './initialState';
import * as reducers from './reducers';
import { NavbarState } from './types';

const createNavbarSlice = (name: string, initialState: NavbarState) => {
  return createSlice({
    name,
    initialState,
    reducers,
  });
};

export const navbarSlice = createNavbarSlice('navbar', initialNavbarState);

export type NavbarSlice = ReturnType<typeof createNavbarSlice>;
