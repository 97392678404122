import { POSITIVE_RATING_PERCENT } from 'common/RatingUtilities';
import { chain } from 'lodash';
import { UpcomingOrderFieldsFragment } from 'src/gqlReactTypings.generated.d';

/**
 * Helper method for determining if all food ratings for the passed in orders have a positive rating.
 */
export const doAllOrdersHaveAPositiveReview = (orders: UpcomingOrderFieldsFragment[]): boolean => {
  return chain(orders)
    .flatMap((order) => order.lineItems)
    .map((lineItem) => lineItem.rating?.ratingPercent)
    .every((ratingPercent) => ratingPercent === POSITIVE_RATING_PERCENT)
    .value();
};
