import { formatCurrency } from 'common/FormatUtilities';
import { lt } from 'common/PatternMatchingUtilities';
import {
  NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY,
  NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY,
} from 'common/PromoCodeUtilities';
import { formatNumOrdersReferralBanner } from 'common/ReferTextUtilities';
import { useCallback } from 'react';
import ConsumerBannerIcon from 'src/assets/svg/multistar.svg';
import { PromoCodeType } from 'src/gqlReactTypings.generated.d';
import { useLocation } from 'src/shared/hooks/useLocation';
import { ALL_WAITLIST_ROUTES, Routes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { match } from 'ts-pattern';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { CloseButton } from './components/DishFirstHeader/styles/modal';
import { useShowConsumerHeaderPersistentBanner } from './components/header-v2/useShowConsumerHeaderPersistentBanner';
import { useMealPlanDiscount } from './meal-plans/useMealPlanDiscount';

export const ConsumerBanner: React.FC = () => {
  const { enabled, handlePromoClose, promoCode } = useShowConsumerHeaderPersistentBanner();
  const { isSmallScreen } = useScreenContext();

  const location = useLocation();
  const hideBanner = locationInRoutes(location, Object.values(ALL_WAITLIST_ROUTES));
  const { promoText, referralPromo: eligibleMealPlanReferralPromo } = useMealPlanDiscount();

  const isOnMealPlanIntake = locationInRoutes(location, [
    Routes.CONSUMER_LANDING,
    Routes.CONSUMER_MEAL_PLANS_PREFERENCES,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS,
    Routes.CONSUMER_COMPLETE_MEAL_PLANS_PREFERENCES,
    Routes.CONSUMER_COMPLETE_MEAL_PLANS_CHECKOUT,
  ]);

  const onBannerClose = useCallback(handlePromoClose, [handlePromoClose]);

  const referralPromoUsesLeft = eligibleMealPlanReferralPromo?.usesRemainingForUser ?? 0;
  const valueText = promoCode?.isPercentage ? `${promoCode?.value}%` : formatCurrency(promoCode?.value, true);
  const bannerMessage = match({
    firstPromoOnly: promoCode?.firstPromoOnly,
    isFreeDelivery: promoCode?.isFreeDelivery,
    isOnMealPlanIntake,
    promoCode: promoCode?.code,
    promoCodeType: promoCode?.promoCodeType,
    referralPromoUsesLeft,
  })
    .with({ isOnMealPlanIntake: true, referralPromoUsesLeft: lt(2) }, () => promoText)
    .with(
      { promoCode: NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY },
      () => 'You get $30 off your first 5 orders - $10 off your first order plus free delivery!'
    )
    .with(
      { promoCode: NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY },
      () => 'You get $50 off your first 5 orders - $10 off your first order plus free delivery!'
    )
    .with({ isFreeDelivery: true }, () => 'Yay! You get free delivery on your next order!')
    .with(
      { promoCodeType: PromoCodeType.Referral },
      () =>
        `You just scored ${formatCurrency(promoCode?.value, true)} off ${formatNumOrdersReferralBanner(
          promoCode?.usesRemainingForUser ?? 0
        )}`
    )
    .with(
      { firstPromoOnly: true },
      () => `You just scored ${formatCurrency(promoCode?.value, true)} off your first order!`
    )
    .otherwise(() => {
      const orderText = formatNumOrdersReferralBanner(promoCode?.usesRemainingForUser ?? 0);
      return `Yay! You get ${valueText} off${orderText && ' '}${orderText}!`;
    });

  const promoBannerHidden = !enabled || hideBanner;

  if (promoBannerHidden) return null;

  return (
    <div id='consumer-banner' className='z-banner flex w-full items-center justify-between bg-mint-300 p-2 font-sans'>
      <div className={`flex w-full items-start justify-center gap-1 text-xs font-semibold`}>
        <img src={ConsumerBannerIcon} alt='icon' className='h-4 w-4' />
        <div className='flex flex-col items-start justify-center gap-0 sm:flex-row sm:items-center sm:gap-1'>
          <span className='text-base font-bold text-neutral-800'>{bannerMessage}</span>
          {!isSmallScreen && (
            <span className={`text-xs font-semibold italic text-neutral-700`}>Discount applied at checkout</span>
          )}
        </div>
      </div>
      <div className='cursor-pointer'>
        <CloseButton onClick={onBannerClose} />
      </div>
    </div>
  );
};
