import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

interface ThumbsDownV2Props extends React.SVGProps<SVGSVGElement> {
  outline?: boolean;
}

export const ThumbsDownV2 = ({ outline = false, ...props }: ThumbsDownV2Props) => {
  if (outline) {
    return <ThumbsDownV2Outline {...props} />;
  }

  return (
    <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <Path
        d='M3 1.03125H1C0.4375 1.03125 0 1.46875 0 2V8.96875C0 9.5 0.4375 9.9375 1 9.9375H3C3.53125 9.9375 4 9.5 4 8.96875V2.03125C4 1.46875 3.53125 1.03125 3 1.03125ZM14.5938 7.53125C14.8438 7.25 15 6.90625 15 6.5C15 5.78125 14.4688 5.15625 13.75 5.03125C13.9062 4.8125 14 4.5625 14 4.25C14 3.5625 13.5625 3 12.9375 2.8125C12.9688 2.75 13 2.625 13 2.5C13 1.6875 12.3125 1 11.5 1H9.65625C8.5625 1 7.53125 1.375 6.65625 2L5.59375 2.8125C5.21875 3.09375 5 3.53125 5 4V9C5 9.4375 5.1875 9.875 5.5625 10.1562L6.0625 10.5625C8.625 12.625 7.46875 15 9.4375 15C10.4062 15 10.9688 14.25 10.9688 13.5C10.9688 13.0312 10.625 11.6875 9.90625 10.5H14.5C15.3125 10.5 16 9.84375 16 9C16 8.21875 15.375 7.5625 14.5938 7.53125Z'
        fill='currentColor'
      />
    </SVG>
  );
};

export const ThumbsDownV2Outline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <Path
        d='M4 8.75V2.25C4 1.5625 3.4375 1 2.75 1H1.25C0.53125 1 0 1.5625 0 2.25V8.75C0 9.46875 0.53125 10 1.25 10H2.75C3.4375 10 4 9.46875 4 8.75ZM3 8.75C3 8.90625 2.875 9 2.75 9H1.25C1.09375 9 1 8.90625 1 8.75V2.25C1 2.125 1.09375 2 1.25 2H2.75C2.875 2 3 2.125 3 2.25V8.75ZM14.9062 7.375C15 7.1875 15.0312 6.9375 15.0312 6.71875C15.0312 6 14.625 5.375 14.0312 5.03125C14.0625 4.90625 14.0938 4.75 14.0938 4.59375C14.0938 3.90625 13.7188 3.28125 13.125 2.9375C13.1562 1.875 12.2812 1 11.2188 1H9.46875C7.0625 1 5 3 5 3.53125C5 3.78125 5.21875 4.03125 5.5 4.03125C6.09375 4 6.9375 2 9.46875 2H11.2188C11.7188 2 12.1562 2.4375 12.1562 2.9375C12.1562 3.09375 12.0938 3.125 12.0938 3.25C12.0938 3.90625 13.0938 3.625 13.0938 4.59375C13.0938 5 12.875 5.0625 12.875 5.34375C12.875 5.625 13.0938 5.78125 13.2812 5.8125C13.7188 5.90625 14.0312 6.28125 14.0312 6.71875C14.0312 7.28125 13.6562 7.3125 13.6562 7.6875C13.6562 7.9375 13.875 8.15625 14.125 8.1875C14.5938 8.21875 15 8.625 15 9.09375C15 9.59375 14.5625 10 14.0625 10H10.0625C9.78125 10 9.59375 10.2188 9.59375 10.5312C9.59375 10.5938 9.59375 10.6875 9.65625 10.75C10.1875 11.75 10.4688 12.8125 10.4688 13.125C10.4375 13.5 10.1562 14 9.5 14C9 14 8.90625 13.875 8.625 13C7.9375 10.9062 7.46875 10.8125 5.84375 9.15625C5.75 9.0625 5.625 9 5.5 9C5.21875 9 5 9.21875 5 9.5C5 10.2188 6.78125 10.625 7.65625 13.2812C7.9375 14.0938 8.21875 15 9.5 15C10.7188 15 11.4375 14.0625 11.4375 13.125C11.4375 12.6875 11.25 11.875 10.875 11H14.0625C15.125 11 16 10.1562 16 9.09375C16 8.34375 15.5625 7.6875 14.9062 7.375Z'
        fill='currentColor'
      />
    </SVG>
  );
};
