import cn from 'classnames';
import React from 'react';

export enum ChipColor {
  NEUTRAL_50 = 'NEUTRAL_50',
  ELDERBERRY_50 = 'ELDERBERRY_50',
  JACKFRUIT_200 = 'JACKFRUIT_200',
  RADISH_50 = 'RADISH_50',
  SOURDOUGH = 'SOURDOUGH',
  WHITE = 'WHITE',
  RASPBERRY = 'RASPBERRY',
}

const colorVariants = {
  [ChipColor.ELDERBERRY_50]: `bg-elderberry-50`,
  [ChipColor.JACKFRUIT_200]: `bg-jackfruit-200`,
  [ChipColor.RADISH_50]: `bg-radish-50`,
  [ChipColor.NEUTRAL_50]: `bg-neutral-50`,
  [ChipColor.SOURDOUGH]: `bg-sourdough`,
  [ChipColor.WHITE]: `bg-white`,
  [ChipColor.RASPBERRY]: `bg-raspberry text-white`,
};

const BASE_CSS = [
  'rounded',
  'flex',
  'gap-1',
  'font-sans',
  'font-semibold',
  'text-xs',
  'px-2',
  'items-center',
  'w-fit',
].join(' ');

interface ChipProps {
  color: ChipColor;
  copy: string;
  applyFullHeight?: boolean;
  icon?: React.ReactNode; // TODO: Restrict to Icon component
}

const Chip: React.FC<ChipProps> = ({ applyFullHeight = false, color, copy, icon }) => (
  <div className={cn(BASE_CSS, colorVariants[color], { 'h-full': applyFullHeight, 'h-6': !applyFullHeight })}>
    {icon && <span className='flex h-full w-5 items-center pb-[2px]'>{icon}</span>}
    <span className='text-nowrap leading-6'>{copy}</span>
  </div>
);
export default Chip;
