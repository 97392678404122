export const cssPixel = (value: number) => {
  return `${value}px`;
};

export const cssPercentage = (value: number) => {
  return `${value}%`;
};

export const cssRem = (value: number) => {
  return `${value}rem`;
};

export const cssEm = (value: number) => {
  return `${value}em`;
};
