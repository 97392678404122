import { QueryParams } from 'common/urls/QueryParams';
import { useCallback } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { RatingPercent } from './constants';
import { isValidRatingPercentValue } from './RatingsUtilities';

export type InitialFoodItemRating = { lineItemId: number; ratingPercent: RatingPercent };

const NULL_RATING_PERCENT = -1;

/**
 * Helper method for parsing the rating percent from the query string.
 * `-1` is converted to `null` because query strings don't support null values.
 */
const parseQueryStringRatingPercent = (ratingPercent: number | undefined) => {
  return ratingPercent === NULL_RATING_PERCENT ? null : ratingPercent;
};

/**
 * Helper method for converting the rating percent to a query string friendly value.
 * `null` is converted to `-1` because query strings don't support null values.
 */
const convertToQueryStringRatingPercent = (ratingPercent: RatingPercent): number => {
  return ratingPercent === null ? NULL_RATING_PERCENT : ratingPercent;
};

type SetInitialFoodItemRatingFunction = (rating: { lineItemId: number; ratingPercent: RatingPercent }) => void;

/**
 * Hook for reading and writing the initial food item rating used for the review modal from the query params.
 */
export const useInitialFoodItemRating = (): [InitialFoodItemRating | null, SetInitialFoodItemRatingFunction] => {
  const [
    {
      [QueryParams.FOOD_ITEM_RATING_LINE_ITEM_ID]: lineItemId,
      [QueryParams.FOOD_ITEM_RATING_RATING_PERCENT]: rawRatingPercent,
    },
    setQueryParams,
  ] = useQueryParams({
    [QueryParams.FOOD_ITEM_RATING_LINE_ITEM_ID]: withDefault(NumberParam, undefined),
    [QueryParams.FOOD_ITEM_RATING_RATING_PERCENT]: withDefault(NumberParam, undefined),
  });

  const setInitialFoodItemRating = useCallback(
    (rating: { lineItemId: number; ratingPercent: RatingPercent }) => {
      setQueryParams({
        [QueryParams.FOOD_ITEM_RATING_LINE_ITEM_ID]: rating.lineItemId,
        [QueryParams.FOOD_ITEM_RATING_RATING_PERCENT]: convertToQueryStringRatingPercent(rating.ratingPercent),
      });
    },
    [setQueryParams]
  );

  const ratingPercent = parseQueryStringRatingPercent(rawRatingPercent);

  if (!lineItemId || !isValidRatingPercentValue(ratingPercent)) {
    return [null, setInitialFoodItemRating];
  }

  return [
    {
      lineItemId,
      ratingPercent,
    },
    setInitialFoodItemRating,
  ];
};
