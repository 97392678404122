export function isNotNull<T>(s: T): s is Exclude<T, null> {
  return s !== null;
}

export function isNotUndefined<T>(s: T): s is Exclude<T, undefined> {
  return s !== undefined;
}

export function isDefined<T>(s: T): s is Exclude<T, undefined | null> {
  return isNotNull(s) && isNotUndefined(s);
}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (!isDefined(val)) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`);
  }
}

export function assertIsNotNull<T>(val: T): asserts val is NonNullable<T> {
  if (!isNotNull(val)) {
    throw new Error(`Expected 'val' to be not null`);
  }
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const makeOpaque = <OpaqueType>(someData: any): OpaqueType => someData as OpaqueType;

/**
 * Use to statically provide type-checking without changing the type of an object.
 */
export function staticTypeCheck<T>(_item: T): void {}

export type StaticTypeCheck<A, B extends A> = B;

export type NullableFields<T> = {
  [P in keyof T]: T[P] | null;
};

export function assertNumber(s: unknown): number {
  if (typeof s !== 'number') {
    throw new Error(`Value is not a number (${s})`);
  }
  return s;
}

export function assertString(s: unknown): string {
  if (typeof s !== 'string') {
    throw new Error(`Value is not a string (${s})`);
  }
  return s;
}

export type JSONObj = { [key: number]: JSONType; [key: string]: JSONType };

/**
 * Used to convert any type (T) into a JSONType, by making any non-jsonable
 * field `never`.
 */
export type IJSONObj<T> = { [K in keyof T]: T[K] extends JSONType ? T[K] : never };

export type JSONType = string | number | boolean | null | undefined | Date | JSONType[] | JSONObj;

export type Left<T> = [T, null];
export type Right<T> = [null, T];
export type Either<T, TT> = Left<T> | Right<TT>;

export function isLeft<T, TT>(val: Either<T, TT>): val is Left<T> {
  return val[0] !== null;
}

export function isRight<T, TT>(val: Either<T, TT>): val is Right<TT> {
  return val[1] !== null;
}

export type PromiseOrValue<T> = Promise<T> | T;
export type Unpromise<T extends Promise<any>> = T extends Promise<infer U> ? U : never;
export type Optional<T> = T | undefined;

export type OptionalFields<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ExceptFields<T, K extends keyof T> = T & { [key in K]?: never };
export type RequiredFields<T> = {
  [P in keyof T]-?: Exclude<T[P], null | undefined>;
};
export type RequireSomeFields<T, K extends keyof T> = RequiredFields<Pick<T, K>> & Omit<T, K>;

/**
 * Removes private properties from a class type.
 */
export type ClassInterface<T> = {
  [K in keyof T]: T[K];
};

/**
 * Used for making Jest mocks that conform to another interface, as well.
 * Every function available on the object will have the correct function
 * signature, in addition to being usable in jest expectations for mocked
 * functions (e.g. expect(fn).toHaveBeenCalled())
 */
export type Mocked<T> = T & {
  [K in keyof T]: T[K] extends (...args: infer U) => any ? jest.Mock<ReturnType<T[K]>, Parameters<T[K]>> : T[K];
};

/**
 * A nested Pick, lets you get fields two levels deep
 */
export type Pick2<T, K1 extends keyof T, K2 extends keyof T[K1]> = {
  [P1 in K1]: {
    [P2 in K2]: T[K1][P2];
  };
};

// Creates a type where some keys are required to be defined and nonnull
// eslint-disable-next-line @typescript-eslint/ban-types
export type MandateProps<T extends {}, K extends keyof T> = Omit<T, K> & {
  [MK in K]-?: NonNullable<T[MK]>;
};

/**
 * Extract T from Promise<T>
 */
export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

/**
 * For every key in T, we want a value that's a string. Useful when you want to do things
 * like group error messages into the same shape as an input type. Example:
 *
 *  interface MyForm {
 *    name: string;
 *    age: number;
 *  }
 *
 *  type MyFormErrors = SameFields<MyForm, string>;
 *
 *  const errors: MyFormErrors = {
 *    age: 'Too young!'
 *  }
 */
export type SameFields<T, K> = Partial<{ [key in keyof T]: K }>;

/**
 *  Dictionary type similiar to Record, but unions values with undefined
 */
export type Dictionary<K extends string | number | symbol, T> = Record<K, T | undefined>;

export type EntityFetcherByNumberKey<ReturnType> = (key: number) => Promise<ReturnType>;
export type EntityFetcherByNumberKeys<ReturnType> = (key: number[]) => Promise<ReturnType[]>;
export type EntityFetcherByStringKey<ReturnType> = (key: string) => Promise<ReturnType>;
export type EntityFetcherByStringKeys<ReturnType> = (key: string[]) => Promise<ReturnType[]>;
export type EntityCountFetcherByStringKey = (key: [string]) => Promise<number>;
export type EntityCountFetcherByStringKeys = (key: [string][]) => Promise<number[]>;
export type EntityCountFetcherByNumberKey = (key: [number]) => Promise<number>;
export type EntityCountFetcherByNumberKeys = (key: [number][]) => Promise<number[]>;

export type ArrayType<T> = T extends (infer U)[] ? U : never;

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

// https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case
export type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;
export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;
