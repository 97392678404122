import { StandardEnum, valueToEnumOrNull, valueToStringEnumOrNull } from 'common/EnumUtils';
import { isDefined } from 'common/TypeUtilities';
import { assertDateStrings, DateString, isValidUrlDate } from 'common/UrlDate';
import { QueryParams } from 'common/urls/QueryParams';
import { filter, isNumber, isString, map, reduce } from 'lodash';
import QueryString, { ParsedQuery } from 'query-string';

export type ParsedQueryStringValue = ParsedQuery<string | null>[string];
export type ParsedQueryNumberValue = ParsedQuery<number | null>[number];

/**
 * Returns a new search string with only the params from queryParams.
 */
export const pickQueryParamsFromSearch = (search: string, queryParams: string[]) => {
  const parsedQueryParams = QueryString.parse(search);
  const pickedQueryParams = reduce(
    queryParams,
    (acc, queryParam) => {
      return {
        ...acc,
        [queryParam]: parsedQueryParams[queryParam],
      };
    },
    {}
  );

  return QueryString.stringify(pickedQueryParams);
};

/**
 * Returns a new search string without the params from queryParamsToOmit.
 */
export const omitQueryParamsFromSearch = (search: string, queryParamsToOmit: string[]) => {
  const parsedQueryParams = QueryString.parse(search);
  const pickedQueryParams = reduce(
    Object.entries(parsedQueryParams),
    (acc, [queryParam, queryValue]) => {
      if (queryParamsToOmit.includes(queryParam)) {
        return acc;
      }

      return {
        ...acc,
        [queryParam]: queryValue,
      };
    },
    {}
  );

  return QueryString.stringify(pickedQueryParams);
};

export interface VanityUrlParamUtilArgs {
  path: string;
  keyword?: string;
  content?: string;
  source?: string;
  campaign?: string;
  promocode?: string;
  medium?: string;
}

export const vanityUrlParamUtil = (paramArgs: VanityUrlParamUtilArgs) => {
  const queryParams = {
    [QueryParams.UTM_KEYWORD]: paramArgs.keyword ?? undefined,
    [QueryParams.UTM_SOURCE]: paramArgs.source,
    [QueryParams.UTM_CAMPAIGN]: paramArgs.campaign,
    [QueryParams.PROMO_CODE]: paramArgs.promocode ?? undefined,
    [QueryParams.UTM_CONTENT]: paramArgs.content ?? undefined,
    [QueryParams.UTM_MEDIUM]: paramArgs.medium,
  };

  return QueryString.stringify(queryParams, {
    encode: true,
  });
};

/**
 * Given a parsed-query value, convert the value to an array of strings.
 */
const paramToStringArray = (value: ParsedQueryStringValue) => {
  if (isString(value)) {
    return [value];
  }

  if (Array.isArray(value)) {
    return filter(value, isString);
  }

  return [];
};

/**
 * Given a parsed-query value, convert the value to an array of strings.
 */
const paramToNumberArray = (value: ParsedQueryNumberValue) => {
  if (isNumber(value)) {
    return [value];
  }

  if (Array.isArray(value)) {
    return filter(value, isNumber);
  }

  return [];
};

/**
 * Given a parsed-query value, validate the value as an array of date strings.
 */
export function validateParamAsDateStrings(value: ParsedQueryStringValue): DateString[] {
  const values = paramToStringArray(value);
  const validUrlDateValues = filter(values, (v) => isDefined(v) && isValidUrlDate(v));
  return assertDateStrings(validUrlDateValues);
}

/**
 * Given a parsed-query value, validate the value as an array of strings.
 */
export function validateParamAsStringArray(value: ParsedQueryStringValue): string[] {
  return filter(paramToStringArray(value), isDefined);
}

/**
 * Given a parsed-query value, return an array with values that only exist in the passed in array.
 */
export function validateParamInArray(value: ParsedQueryStringValue, arr: string[]): string[] {
  const values = paramToStringArray(value).map((v) => v.toLowerCase());
  return filter(arr, (arrValue) => values.includes(arrValue.toLowerCase()));
}

/**
 * Given a parsed-query value, return an array with values that only exist in the passed in enum.
 */
export const validateParamsInEnum = <T extends StandardEnum, K extends keyof T>(
  value: ParsedQueryStringValue,
  enumObj: T
): T[K][] => {
  const values = paramToStringArray(value);
  const enumValues = map(values, (v) => valueToStringEnumOrNull({ theEnum: enumObj, value: v, caseSensitive: false }));
  return enumValues.filter(isDefined);
};

/**
 * Given a parsed-query value, return an array with values that only exist in the passed in enum.
 */
export const validateParamInNumberEnum = <T extends StandardEnum, K extends keyof T>(
  value: ParsedQueryNumberValue,
  enumObj: T
): T[K][] => {
  const values = paramToNumberArray(value);
  const enumValues = map(values, (v) => valueToEnumOrNull(enumObj, v));
  return enumValues.filter(isDefined);
};
