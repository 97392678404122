import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const InfoCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <Path d='M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2L12,2z M13,10h-2v7h2V10z M12,6c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2S12.7,6,12,6z' />
    </SVG>
  );
};
