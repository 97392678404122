import { isNil, sumBy } from 'lodash';
import { CartShef, CartShefAvailability, EditableOrder, FoodItemAvailability } from 'src/store/cart/types';
import { match } from 'ts-pattern';

// shef utils

interface GetAdjustedNumDishesAvailable {
  shefAvailability: CartShefAvailability[];
  rawOrderData: EditableOrder;
  deliveryDate: string;
}

export const getAdjustedNumDishesAvailable = ({
  shefAvailability,
  rawOrderData,
  deliveryDate,
}: GetAdjustedNumDishesAvailable): number => {
  const availability = shefAvailability.find(({ availabilityDate }) => availabilityDate === deliveryDate);
  const numDishesAvailable = availability?.numDishesAvailable ?? 0;

  // have to ts-ignore type because of weird issue caused by immer
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { lineItems = [], deliveryDate: orderDeliveryTimeStamp } = rawOrderData || {};
  const orderLineItemQuantity = sumBy(lineItems || [], 'quantity');
  const isSameDayAsOrder = (orderDeliveryTimeStamp || '').startsWith(deliveryDate);
  const result = numDishesAvailable + (isSameDayAsOrder ? orderLineItemQuantity : 0);
  return result;
};

export const isShefSoldOutForDate = (params: GetAdjustedNumDishesAvailable): boolean =>
  getAdjustedNumDishesAvailable(params) <= 0;

// food items utils

interface GetAdjustedFoodItemAvailability {
  foodItemsAvailabilities: FoodItemAvailability[];
  deliveryDate: string | null | undefined;
  quantityInEditingOrder: number;
}

export const getAdjustedFoodItemAvailability = ({
  foodItemsAvailabilities,
  deliveryDate,
  quantityInEditingOrder,
}: GetAdjustedFoodItemAvailability): number | null => {
  if (!deliveryDate) return null;

  const deliveryDateAvailability = foodItemsAvailabilities.find((a) =>
    a.availabilityDate.startsWith(deliveryDate)
  )?.numAvailable;
  if (isNil(deliveryDateAvailability)) return null;

  return deliveryDateAvailability + quantityInEditingOrder;
};

export const isFoodItemAvailable = (params: GetAdjustedFoodItemAvailability): boolean =>
  (getAdjustedFoodItemAvailability(params) ?? 0) > 0;

export const DEFAULT_CAPACITY_INFO = {
  isOrderingAllRemainingItems: false,
  isOrderingMoreThanDeliveryDateCapacity: false,
  dishCapacity: Infinity,
  numDishesAvailable: Infinity,
  numDishesRemaining: Infinity,
};

export const getShefDeliveryDateCapacityInfo = (
  shef: CartShef | undefined,
  shefLineItemCount: number,
  deliveryDate: string,
  rawOrderData: EditableOrder
) =>
  match(shef)
    .with({ loading: false }, (shef) => {
      const numDishesAvailable = getAdjustedNumDishesAvailable({
        shefAvailability: shef.availability,
        deliveryDate,
        rawOrderData,
      });
      const numDishesRemaining = numDishesAvailable - shefLineItemCount ?? 0;
      return {
        dishCapacity: shef.shefProfile.dishCapacity,
        numDishesAvailable,
        numDishesRemaining,
        isOrderingAllRemainingItems: numDishesAvailable > 0 && numDishesRemaining <= 0,
        isOrderingMoreThanDeliveryDateCapacity: numDishesRemaining < 0,
      };
    })
    .otherwise(() => DEFAULT_CAPACITY_INFO);
