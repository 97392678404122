import React, { useEffect } from 'react';
import { useScreenContext } from '../../../context/ScreenContext/hook';
import { BaseModal, BaseModalProps, ModalType } from './components/BaseModal';
import { ModalBackdrop } from './components/ModalBackdrop';
import { Portal } from './components/Portal';

export const Modal: React.FC<BaseModalProps> = ({ children, modalType = ModalType.STANDARD, isShowing, onClose }) => {
  const { isLargeScreen } = useScreenContext();
  const hideOverlay = modalType === ModalType.DOCKED && !isLargeScreen;
  const container = document.getElementById('root') ?? undefined;

  useEffect(() => {
    if (isShowing) {
      document.body.classList.add('overflow-hidden');
    } else if (!isShowing) {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isShowing, container]);

  return (
    <Portal>
      <ModalBackdrop onClick={onClose} isShowing={isShowing && !hideOverlay} />
      <BaseModal onClose={onClose} modalType={modalType} isShowing={isShowing}>
        {children}
      </BaseModal>
    </Portal>
  );
};
