import { ClientEvents } from 'common/events/ClientEvents';
import React, { Suspense, useCallback, useState } from 'react';
import { useTracker } from 'src/shared/hooks/useTracker';
import Notifications from 'src/shared/Notifications';
import { useGlobalReducer } from 'src/shef-global-state/shefGlobalState';
import { lazyRetry } from 'src/util/retry';
import { Channel as StreamChannel } from 'stream-chat';
import 'stream-chat-react/dist/css/index.css';
import { getSession } from '../tracking/tracking';
import { ChatContext, ChatContextType, ChatRole } from './ChatContext';
import { useChatClient } from './hooks/useChatClient';
import { useChatRole } from './hooks/useChatRole';

const ChatWidget = lazyRetry(() => import('./components/ChatWidget/ChatWidget'));

export const ChatProvider: React.FC = ({ children }) => {
  const { client, loading, unread } = useChatClient();
  const role = useChatRole();

  const { state } = useGlobalReducer();
  const tracker = useTracker();

  const [channel, setChannel] = useState<StreamChannel | null>(null);

  const openWidget: ChatContextType['openWidget'] = useCallback(
    async (recipient) => {
      if (!client?.userID) {
        return;
      }

      const shefId = role === ChatRole.CONSUMER ? recipient.flakeId : client.userID;
      const consumerId = role === ChatRole.CONSUMER ? client.userID : recipient.flakeId;
      tracker.track(ClientEvents.CHAT_WIDGET_OPEN, { shefId, consumerId });

      try {
        const newChannel = client.channel('messaging', {
          members: [client.userID, recipient.flakeId],
          shef: { id: shefId },
          consumer: { id: consumerId },
        });

        await newChannel.watch();
        setChannel(newChannel);
      } catch (error: unknown) {
        Notifications.error(`Could not message ${recipient.publicName}`);
        console.error(error);
      }
    },
    [client, role, tracker]
  );

  const closeWidget: ChatContextType['closeWidget'] = useCallback(async () => {
    await channel?.stopWatching();
    setChannel(null);
  }, [channel]);

  return (
    <ChatContext.Provider
      value={{
        client,
        loading,
        role,
        unread,
        tracking: {
          user_id: state.currentUser?.id ?? undefined,
          region_id: state.userRegionId ?? undefined,
          device_id: tracker.browserTrackerId,
          session_id: getSession()?.id,
        },
        openWidget: client ? openWidget : undefined,
        closeWidget: client ? closeWidget : undefined,
      }}>
      {children}
      {/* FUTURE TODO: create loading state fallback for Suspense */}
      {channel && (
        <Suspense fallback={null}>
          <ChatWidget channel={channel} onClose={closeWidget} />
        </Suspense>
      )}
    </ChatContext.Provider>
  );
};
