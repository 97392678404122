import { checkEmail } from '@shef/validators';
import { isFacebookBrowser, isInstagramBrowser } from 'common/BrowserUtilities';
import { ShefErrorCode } from 'common/errors/ShefErrors';
import { isLeft } from 'fp-ts/lib/Either';
import { GraphQLError } from 'graphql';
import { includes, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  OAuthLoginType,
  OAuthMissingFieldsResult,
  useGetRegisteredEmailLazyQuery,
} from 'src/gqlReactTypings.generated.d';
import { useFeatureFlag } from 'src/shared/design-system/FeatureFlag';
import { FormErrorMessage } from 'src/shared/design-system/Input/Error/FormErrorMessage';
import { LabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/LabeledTextInput';
import styled from 'styled-components';
import { Spacing } from 'web-common/src/shared/styles';
import { AppleLogin } from '../oAuth/AppleLogin';
import { FacebookLoginV2 } from '../oAuth/FacebookLoginV2';
import { GoogleLoginV2 } from '../oAuth/GoogleLoginV2';
import { GoogleLoginV3 } from '../oAuth/GoogleLoginV3';
import { AuthActionButton } from './AuthActionButton';

// Negative margin, since we're using gap for spacing
const ErrorMessageWithMargin = styled(FormErrorMessage)`
  margin-top: -${Spacing.HALF};
`;

enum AuthType {
  GOOGLE,
  FACEBOOK,
  EMAIL,
  APPLE,
}

interface LogInOrSignUpModalEntryFormError {
  type: AuthType;
  errorMessage: string;
}

interface LogInOrSignUpModalEntryFormProps {
  email: string;
  onEmailChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onEmailContinueSuccess: (email: string, isRegisteredUser: boolean) => void;
  onOAuthSuccess?: (isExistingUser: boolean) => void;
  onOAuthMissingFields: (type: OAuthLoginType, token: string, missingFields: OAuthMissingFieldsResult) => void;
  requireZipCode?: boolean;
  formCloseButton: JSX.Element | null;
  signUpEntryPoint?: string | null;
  continueCTA?: string | null;
}

const isGraphQLError = (error: Error): error is GraphQLError => 'extensions' in error;

const ALLOW_LISTED_ERROR_CODES = [ShefErrorCode.FACEBOOK_SIGN_UP_NO_EMAIL, ShefErrorCode.GOOGLE_BROWSER_NOT_SUPPORTED];
const isAllowListedError = (error: Error): boolean => {
  if (!isGraphQLError(error)) {
    return false;
  }

  return includes(ALLOW_LISTED_ERROR_CODES, error.extensions.code);
};

export const LogInOrSignUpModalEntryForm: React.FC<LogInOrSignUpModalEntryFormProps> = ({
  email,
  onEmailChange,
  onEmailContinueSuccess,
  onOAuthSuccess,
  onOAuthMissingFields,
  requireZipCode,
  formCloseButton,
  signUpEntryPoint,
  continueCTA,
}) => {
  const [error, setError] = useState<LogInOrSignUpModalEntryFormError | undefined>();
  const [emailError, setEmailError] = useState<string | undefined>();
  const signInWithAppleEnabled = useFeatureFlag('appleSignInOnWebEnabled');
  const useGoogleLoginV3 = useFeatureFlag('googleSignInOAuth2Enabled');

  // Hide Google + Apple login on FB browser only
  const isFacebookOrInstagramFunnel = isFacebookBrowser(navigator.userAgent) || isInstagramBrowser(navigator.userAgent);

  const resetErrors = () => {
    setError(undefined);
    setEmailError(undefined);
  };

  const [getRegisteredEmail, { error: getRegisteredEmailError, data: getRegisteredEmailData }] =
    useGetRegisteredEmailLazyQuery({ fetchPolicy: 'network-only' });

  /**
   * So annoyed with this hack – as far as I can tell LazyQuery doesn't give you a good callback or promised-based way of getting the GraphQLErrors, so
   * so have to use useEffect. In addition, the lazyQuery loading is not set to true when the query is retried, so we have to hack around with our own.
   */
  const [getRegisteredEmailLoading, setGetRegisteredEmailLoading] = useState(false);

  useEffect(() => {
    if (getRegisteredEmailLoading) {
      return;
    }

    if (!isNil(getRegisteredEmailError)) {
      const invalidEmailErrors = getRegisteredEmailError.graphQLErrors.filter(
        (graphQLError) => graphQLError.extensions.code === ShefErrorCode.INVALID_EMAIL
      );
      if (invalidEmailErrors.length > 0) {
        setError({ type: AuthType.EMAIL, errorMessage: invalidEmailErrors[0].message });
      } else {
        setError({
          type: AuthType.EMAIL,
          errorMessage: 'There was an issue processing your request. Please try again.',
        });
      }
    } else if (getRegisteredEmailData) {
      const registeredEmail = getRegisteredEmailData.getRegisteredEmail;
      const userIsRegistered = !isNil(registeredEmail);

      onEmailContinueSuccess(registeredEmail ?? email, userIsRegistered);
    }
  }, [getRegisteredEmailError?.message, getRegisteredEmailData?.getRegisteredEmail, getRegisteredEmailLoading]);

  const handleEmailContinue = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (getRegisteredEmailLoading) {
      return;
    }

    resetErrors();

    const emailCheck = checkEmail(email);
    if (isLeft(emailCheck)) {
      setEmailError(emailCheck.left);
    } else {
      getRegisteredEmail({ variables: { email: email.trim() } }).then(() => setGetRegisteredEmailLoading(false));
      setGetRegisteredEmailLoading(true);
    }
  };

  // Theoretically this shouldn't be needed – not sure why enter isn't handling form submission like normal
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleEmailContinue();
    }
  };

  const displayError = (type: AuthType, providerName: string) => (error: Error) => {
    const message = isAllowListedError(error)
      ? error.message
      : `There was an issue logging in with ${providerName}. Please try again.`;
    setError({ type, errorMessage: message });
  };
  const GoogleLoginComponent = useGoogleLoginV3 ? GoogleLoginV3 : GoogleLoginV2;

  return (
    <div className='flex flex-col gap-4'>
      {!isFacebookOrInstagramFunnel && (
        <GoogleLoginComponent
          onMissingFields={onOAuthMissingFields}
          onAttempt={resetErrors}
          onSuccess={onOAuthSuccess}
          onError={displayError(AuthType.GOOGLE, 'Google')}
          requireZipCode={requireZipCode}
          signUpEntryPoint={signUpEntryPoint}
        />
      )}
      {error && error.type === AuthType.GOOGLE ? (
        <ErrorMessageWithMargin>{error.errorMessage}</ErrorMessageWithMargin>
      ) : null}

      {!isFacebookOrInstagramFunnel && (
        <FacebookLoginV2
          onMissingFields={onOAuthMissingFields}
          onAttempt={resetErrors}
          onSuccess={onOAuthSuccess}
          onError={displayError(AuthType.FACEBOOK, 'Facebook')}
          requireZipCode={requireZipCode}
          signUpEntryPoint={signUpEntryPoint}
        />
      )}
      {error && error.type === AuthType.FACEBOOK ? (
        <ErrorMessageWithMargin>{error.errorMessage}</ErrorMessageWithMargin>
      ) : null}

      {signInWithAppleEnabled ? (
        <>
          <AppleLogin
            onMissingFields={onOAuthMissingFields}
            onAttempt={resetErrors}
            onSuccess={onOAuthSuccess}
            onError={displayError(AuthType.APPLE, 'Apple')}
            requireZipCode={requireZipCode}
            signUpEntryPoint={signUpEntryPoint}
          />
          {error && error.type === AuthType.APPLE ? (
            <ErrorMessageWithMargin>{error.errorMessage}</ErrorMessageWithMargin>
          ) : null}
        </>
      ) : null}
      <div className='text-center text-base font-semibold leading-5'> Or </div>
      <form onSubmit={handleEmailContinue} onKeyDown={handleKeyDown}>
        <fieldset className='flex flex-col items-center gap-4' disabled={getRegisteredEmailLoading}>
          <LabeledTextInput
            label='Email'
            name='email'
            value={email}
            onChange={onEmailChange}
            errorMessage={emailError}
            data-cy='login-entry-email'
          />
          {error && error.type === AuthType.EMAIL ? (
            <ErrorMessageWithMargin>{error.errorMessage}</ErrorMessageWithMargin>
          ) : null}
          <AuthActionButton
            value={continueCTA ?? 'Continue'}
            disabled={getRegisteredEmailLoading}
            data-cy='login-entry-continue'
          />
          {formCloseButton}
        </fieldset>
      </form>
    </div>
  );
};
