import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const ShareArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='16' height='16' viewBox='0 0 16 16' {...props}>
      <Path
        d='M15.7,7.1l-5.5,4.7C9.8,12.2,9,11.9,9,11.2V8.5c-4.9,0.1-6.9,1.2-5.5,5.8c0.2,
           0.5-0.5,0.9-0.9,0.6C1.2,13.9,0,12,0,10.1c0-4.7,4-5.8,9-5.8V1.8c0-0.7,0.8-1,
           1.2-0.6l5.5,4.7C16.1,6.2,16.1,6.8,15.7,7.1z'
      />
    </SVG>
  );
};

export const ShareArrowV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M4.75 5.73621H13.2188L9.25 2.32489C8.9375 2.04322 8.90625 1.57377 9.15625 1.2608C9.4375 0.947839 9.90625 0.916542 10.2188 1.16692L15.7188 5.8927C15.875 6.08048 16 6.26826 16 6.48733C16 6.70641 15.875 6.92548 15.7188 7.08197L10.2188 11.8077C10.0938 11.9329 9.90625 11.9955 9.75 11.9955C9.53125 11.9955 9.3125 11.9016 9.15625 11.7139C8.90625 11.4009 8.9375 10.9314 9.25 10.6498L13.2188 7.23845H4.75C2.9375 7.23845 1.5 8.67809 1.5 10.462V14.1863C1.5 14.687 1.15625 15 0.75 15C0.3125 15 0 14.687 0 14.2489V10.5246C0 7.89568 2.125 5.73621 4.75 5.73621Z'
        fill='currentColor'
      />
    </svg>
  );
};
