import React from 'react';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { Button } from './Button';

export interface IButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Automatically adds border between buttons. Mainly use if group of primary buttons. */
  autoBorders?: boolean;
  /** If button group should fit entire width of parent (flex) container. */
  fluid?: boolean;
}

const Container = styled.div<IButtonGroupProps>`
  display: flex;
  justify-content: center;

  & > ${Button} {
    ${ifProp(
      'fluid',
      css`
        flex: 1 1 auto;
      `
    )}
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      ${ifProp(
        'autoBorders',
        css`
          border-left-width: 1px;
          border-left-style: solid;
        `
      )}
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -1px;
      ${ifProp(
        'autoBorders',
        css`
          border-right-width: 1px;
          border-right-style: solid;
        `
      )}
    }
    &[data-selected='true'] {
      z-index: 1;
    }
    &:hover {
      z-index: 2;
    }
  }

  & > a {
    ${ifProp(
      'fluid',
      css`
        & ${Button} {
          flex: 1 1 auto;
        }
      `
    )}
    &:not(:first-child) > ${Button} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      ${ifProp(
        'autoBorders',
        css`
          border-left-width: 1px;
          border-left-style: solid;
        `
      )}
    }
    &:not(:last-child) > ${Button} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -1px;
      ${ifProp(
        'autoBorders',
        css`
          border-right-width: 1px;
          border-right-style: solid;
        `
      )}
    }
    & > ${Button}[data-selected='true'] {
      z-index: 1;
    }
    &:hover,
    &:hover > ${Button} {
      z-index: 2;
    }
  }
`;

export const ButtonGroup: React.FC<IButtonGroupProps> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};
