import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const ArrowSoftRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    // View box is a little off to keep arrow centered
    <SVG height={16} width={16} viewBox={'0 0 16 14.5'} {...props}>
      {/* eslint-disable-next-line max-len -- SVG path properties */}
      <Path d='M0.500001 6.99573C0.500002 7.61136 0.969014 8.09019 1.53853 8.09019L11.8233 8.09019L8.3057 11.7156C7.87019 12.126 7.87019 12.8443 8.3057 13.2547C8.5067 13.4599 8.77471 13.5625 9.04272 13.5625C9.34423 13.5625 9.61223 13.4599 9.81324 13.2547L15.1734 7.78237C15.6089 7.37195 15.6089 6.65371 15.1734 6.24328L9.81323 0.770971C9.41122 0.326346 8.7077 0.326346 8.3057 0.770972C7.87018 1.1814 7.87018 1.89964 8.3057 2.31006L11.8233 5.90126L1.53853 5.90127C0.969013 5.90127 0.500001 6.4143 0.500001 6.99573Z' />
    </SVG>
  );
};
