import { createSlice } from '@reduxjs/toolkit';
import { initialSearchState } from './initialState';
import * as reducers from './reducers';
import { SearchState } from './types';

const createSearchSlice = (name: string, initialState: SearchState) => {
  return createSlice({
    name,
    initialState,
    reducers,
  });
};

export const searchSlice = createSearchSlice('search', initialSearchState);
