import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

/**
 * Deprecated version of the right chevron. Use ChevronRightV2 instead.
 * This chevron is no longer a part of the design system.
 */
export const ChevronRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <Path d='M7,19c0-0.38,0.15-0.77,0.44-1.06L13.38,12L7.44,6.06c-0.59-0.59-0.59-1.54,0-2.12c0.59-0.59,1.54-0.59,2.12,0l7,7c0.59,0.59,0.59,1.54,0,2.12l-7,7c-0.59,0.59-1.54,0.59-2.12,0C7.15,19.77,7,19.38,7,19z' />
    </SVG>
  );
};

export const ChevronRightV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <Path d='M9 5L16 12L9 19' stroke='currentColor' strokeWidth='2' fill='none' />
    </SVG>
  );
};
