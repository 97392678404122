import styled, { css } from 'styled-components';
import { Colors, ScreenWidth } from 'web-common/src/shared/Constants';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Font } from 'web-common/src/shared/styles';
import { ITextProps } from './ITextProps';

export const Paragraph = styled.p<ITextProps>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : Font.Family.SANS)};
  font-size: ${(props) => (props.size ? props.size : '24px')};
  color: ${(props) => (props.color ? props.color : props.white ? 'white' : 'black')};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px')};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterSpacing};

  ${ifProp(
    'strikeThrough',
    css`
      text-decoration: line-through;
    `
  )}

  border-bottom: ${(props) => (props.underline ? `2px solid ${Colors.RADISH}` : 'none')};

  font-weight: ${({ weight, bold }) => {
    if (weight) {
      return weight;
    }
    if (bold) {
      return '400';
    }
    return '300';
  }};

  @media (max-width: ${ScreenWidth.SMALL}) {
    font-size: ${(props) => (props.mobileSize ? props.mobileSize : props.size)};
    margin: ${(props) => props.marginMobile || props.margin || '0 0 10px'};
    line-height: ${(props) => props.mobileLineHeight || props.lineHeight};
  }
`;
