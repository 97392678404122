import { Colors } from 'web-common/src/shared/styles';

export type ToggleProps = {
  checked?: boolean;
  checkedColor?: string;
  className?: string;
  dataTest?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  showCheckedIcon?: boolean;
  size?: number;
};

export type ToggleBarOption<T, K extends keyof T> = {
  dataTest?: string;
  keyOption?: K;
  label?: React.ReactNode;
  value: T;
};

export type ToggleBarProps<T, K extends keyof T> = {
  className?: string;
  dataTest?: string;
  height?: number;
  onChange?: (value: T, e: React.MouseEvent<HTMLButtonElement>) => void;
  options: ToggleBarOption<T, K>[];
  padding?: number;
  value?: T;
};

export type ToggleYesNoProps = {
  className?: string;
  dataTest?: string;
  noLabel?: React.ReactNode;
  onChange?: (value: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  padding?: number;
  yesLabel?: React.ReactNode;
  value?: boolean;
};

export enum ToggleButtonSize {
  L = 'large',
  S = 'small',
}

export type ToggleButtonOption<T, K extends keyof T> = {
  dataTest?: string;
  disabled?: boolean;
  keyOption?: K;
  icon?: React.ReactNode;
  label?: React.ReactNode | string;
  prependContent?: React.ReactNode;
  selectedColor?: Colors;
  selectedColorHighlight?: Colors;
  value: T;
};

export type ToggleButtonsProps<T, K extends keyof T> = {
  className?: string;
  dataTest?: string;
  disabled?: boolean;
  inlineIcon?: boolean;
  onChange?: (value: T, e: React.MouseEvent<HTMLButtonElement>) => void;
  options: ToggleButtonOption<T, K>[];
  overflowWrap?: boolean;
  selectedColor?: Colors;
  selectedColorHighlight?: Colors;
  size?: ToggleButtonSize;
  value?: T;
};

export interface ToggleButtonMultiplesProps<T, K extends keyof T>
  extends Omit<ToggleButtonsProps<T, K>, 'onChange' | 'value'> {
  onChange?: (value: T[], e: React.MouseEvent<HTMLButtonElement>) => void;
  value?: T[];
}

export type ToggleButtonProps = {
  className?: string;
  dataTest?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  imageUrl?: string;
  inlineIcon?: boolean;
  label?: React.ReactNode | string;
  onChange?: (value: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  prependContent?: React.ReactNode;
  selectedColor?: Colors;
  selectedColorHighlight?: Colors;
  size?: ToggleButtonSize;
  value?: boolean | null;
};
