import { isNil } from 'lodash';
import React, { useState } from 'react';
import { ifNotProp } from 'src/shared/styled-utils/ifNotProp';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { Colors, Spacing } from 'web-common/src/shared/styles';
import { FormErrorMessage } from '../Error/FormErrorMessage';
import { Input, Label, LabelSpan, LABEL_COLOR } from './styles';

interface LabeledTextInputProps {
  topLabel: string;
  topName: string;
  topValue: string;
  onTopChange: (event: React.FormEvent<HTMLInputElement>) => void;
  bottomLabel: string;
  bottomName: string;
  bottomValue: string;
  onBottomChange: (event: React.FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  autoFocusTop?: boolean;
  errorMessage?: string;
  width?: string;
  'top-data-cy'?: string;
  'bottom-data-cy'?: string;
}

const Container = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputBorder = styled.div<{ hasError: boolean }>`
  position: absolute;
  box-sizing: border-box;
  background: none;
  border: 1px solid;
  border-radius: ${Spacing.HALF};
  width: 100%;
  height: 100%;

  ${ifProp(
    'hasError',
    css`
      background-color: ${Colors.RADISH_LITE_20};
      border-color: ${Colors.RADISH};
    `,
    css`
      border-color: ${LABEL_COLOR};
    `
  )}
`;

// kinda hacky styling to make text not shift when focused due to border changes
const LabelStyle = css`
  position: relative;
  box-sizing: content-box;
  width: calc(100% - 2px);
`;

// Have to resize the input because of the different box-sizing
const ResizedInput = styled(Input)`
  height: 56px;
`;

const TopLabel = styled(Label)<{ focused: boolean }>`
  border-radius: ${ifProp('focused', Spacing.HALF, `${Spacing.HALF} ${Spacing.HALF} ${Spacing.NONE} ${Spacing.NONE}`)};
  border-bottom-width: ${ifNotProp('focused', '0px')};
  ${LabelStyle}
`;

const BottomLabel = styled(Label)<{ focused: boolean }>`
  border-radius: ${ifProp('focused', Spacing.HALF, `${Spacing.NONE} ${Spacing.NONE} ${Spacing.HALF} ${Spacing.HALF}`)};
  border-top-width: ${ifNotProp('focused', '0px')};
  ${LabelStyle}
`;

const ErrorMessageWithMargin = styled(FormErrorMessage)`
  margin-top: ${Spacing.HALF};
`;

const Divider = styled.div<{ hasError: boolean }>`
  position: relative;
  height: 1px;
  width: 100%;
  background: ${ifProp('hasError', Colors.RADISH, LABEL_COLOR)};
`;

export const DualLabeledTextInput: React.FC<LabeledTextInputProps> = (props) => {
  const {
    topLabel,
    topName,
    topValue,
    onTopChange,
    bottomLabel,
    bottomName,
    bottomValue,
    onBottomChange,
    disabled,
    autoFocusTop,
    errorMessage,
    width = '100%',
  } = props;

  const [topFocused, setTopFocused] = useState(false);
  const [bottomFocused, setBottomFocused] = useState(false);

  const showTopLabel = topValue.length !== 0;
  const showBottomLabel = bottomValue.length !== 0;
  const hasError = !isNil(errorMessage) && errorMessage.length > 0;

  const topLabelComponent = (
    <TopLabel showLabel={showTopLabel} focused={topFocused} hasError={hasError} htmlFor={topName}>
      {showTopLabel ? <LabelSpan>{topLabel}</LabelSpan> : null}
      <ResizedInput
        showLabel={showTopLabel}
        name={topName}
        placeholder={topLabel}
        value={topValue}
        onChange={onTopChange}
        disabled={disabled}
        onFocus={() => setTopFocused(true)}
        onBlur={() => setTopFocused(false)}
        autoFocus={autoFocusTop}
        data-cy={props['top-data-cy']}></ResizedInput>
    </TopLabel>
  );

  const bottomLabelComponent = (
    <BottomLabel showLabel={showBottomLabel} focused={bottomFocused} hasError={hasError} htmlFor={bottomName}>
      {showBottomLabel ? <LabelSpan>{bottomLabel}</LabelSpan> : null}
      <ResizedInput
        showLabel={showBottomLabel}
        name={bottomName}
        placeholder={bottomLabel}
        value={bottomValue}
        onChange={onBottomChange}
        disabled={disabled}
        onFocus={() => setBottomFocused(true)}
        onBlur={() => setBottomFocused(false)}
        data-cy={props['bottom-data-cy']}></ResizedInput>
    </BottomLabel>
  );

  const errorComponent = hasError ? <ErrorMessageWithMargin>{errorMessage}</ErrorMessageWithMargin> : null;

  return (
    <Container width={width}>
      <InputContainer>
        <InputBorder hasError={hasError} />
        {topLabelComponent}
        {topFocused || bottomFocused ? null : <Divider hasError={hasError} />}
        {bottomLabelComponent}
      </InputContainer>
      {errorComponent}
    </Container>
  );
};
