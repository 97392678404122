import { ApolloQueryResult } from '@apollo/client';
import { ClientEvents } from 'common/events/ClientEvents';
import { omit } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AlgoliaPaginationResult, GetSearchResultsQuery } from 'src/gqlReactTypings.generated.d';
import { useTracker } from 'src/shared/hooks/useTracker';
import { DEFAULT_PAGE_SIZE } from 'src/store/search';
import { BASE_SEARCH_VARIABLES } from '../../consts';
import { UseGetSearchResultsProps } from '../types';
import { usePageSizeReducer } from '../usePageSize';
import { useSearchFilters } from '../useSearchFilters';
import { useGetSearchResultsQuery } from './useGetSearchResultsQuery';

export const useGetSearchResults = (props: UseGetSearchResultsProps) => {
  const { query } = props;
  const tracker = useTracker();

  const { deliveryDate, searchFilters, updateQueryParams, zipCode } = useSearchFilters();
  const { data, error, fetchMore, getSearchResults, loading } = useGetSearchResultsQuery({
    deliveryDate,
    query,
    zipCode,
  });

  const filters = useRef(searchFilters);
  const activeZipCode = useRef(zipCode);
  const deliveryDateRef = useRef(deliveryDate);
  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data -- Update ref when filters change.
    filters.current = searchFilters;
    activeZipCode.current = zipCode;
    deliveryDateRef.current = deliveryDate;
  }, [searchFilters, zipCode, deliveryDate]);

  const { dishPageSize, shefPageSize, changePageSize } = usePageSizeReducer();

  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_PAGE_SIZE);

  const [isMoreResultsLoading, setIsMoreResultsLoading] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);

  const [seenRecordIds, setSeenRecordIds] = useState<number[][]>(BASE_SEARCH_VARIABLES.searchState.seenRecordIds);
  const [paginationState, setPaginationStateAction] = useState<AlgoliaPaginationResult>(
    BASE_SEARCH_VARIABLES.searchState.paginationState
  );
  const setPaginationState = (newPaginatedState: AlgoliaPaginationResult) => {
    setPaginationStateAction(omit(newPaginatedState, ['__typename']));
  };

  const handleSearch = useCallback(
    (newQuery: string, isPartialQuery: boolean) => {
      changePageSize(0, 0);
      setCurrentLimit(DEFAULT_PAGE_SIZE);
      setHasMoreResults(true);

      return getSearchResults({
        variables: {
          ...BASE_SEARCH_VARIABLES,
          query: newQuery,
          deliveryDate: deliveryDateRef.current ?? deliveryDate,
          zipCode: activeZipCode.current ?? '',
          isPartialQuery,
          filters: filters.current,
        },
      }).then((results) => {
        if (newQuery.length > 0 && results.data?.getSearchResults) {
          const { cuisineResults, dishResults, searchStateResult, shefResults } = results.data.getSearchResults;
          const numDishes = dishResults.flatMap((dishResultsList) => dishResultsList).length;
          changePageSize(shefPageSize + shefResults.length, dishPageSize + numDishes);

          setCurrentOffset(dishPageSize + numDishes);

          setPaginationState(searchStateResult.paginationState);
          setSeenRecordIds(searchStateResult.seenRecordIds);

          tracker.track(ClientEvents.FOOD_SEARCH, {
            value: newQuery,
            numResults:
              cuisineResults.length +
                shefResults.length +
                dishResults[0].length +
                dishResults[1].length +
                dishResults[2].length +
                dishResults[3].length ?? 0,
          });
        }
      });
    },
    [deliveryDate, dishPageSize, shefPageSize, tracker]
  );

  const handleSearchMoreResults = async () => {
    if (!!fetchMore && !loading && !isMoreResultsLoading) {
      setIsMoreResultsLoading(true);
      const results: ApolloQueryResult<GetSearchResultsQuery> = await fetchMore({
        variables: {
          ...BASE_SEARCH_VARIABLES,
          query,
          deliveryDate,
          zipCode: activeZipCode.current ?? '',
          isPartialQuery: false,
          filters: filters.current,
          searchState: {
            paginationState,
            seenRecordIds,
          },
          offset: currentOffset,
          limit: currentLimit,
        },
      });
      const { dishResults, shefResults, searchStateResult } = results.data.getSearchResults;
      const numDishes = dishResults.flatMap((dishResultsList) => dishResultsList).length;
      changePageSize(shefPageSize + shefResults.length, dishPageSize + numDishes);

      setCurrentOffset(dishPageSize + numDishes);

      setPaginationState(searchStateResult.paginationState);
      setSeenRecordIds(searchStateResult.seenRecordIds);

      setHasMoreResults(!searchStateResult.paginationState.isEnd);
      setIsMoreResultsLoading(false);
    }
  };

  return {
    data,
    dishPageSize,
    error,
    fetchMore,
    handleSearch,
    handleShowMoreShefs: handleSearchMoreResults,
    handleShowMoreDishes: handleSearchMoreResults,
    hasMoreResults,
    isMoreResultsLoading,
    loading,
    searchFilters,
    shefPageSize,
    updateQueryParams,
    zipCode,
  };
};
