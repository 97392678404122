import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Checkmark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <Path d='M10.1,21l-7.5-7.5c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0L9.9,15l8.6-10.3c0.7-0.8,2-1,2.8-0.3s1,2,0.3,2.8L10.1,21z' />
    </SVG>
  );
};
