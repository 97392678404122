import React from 'react';
import { UpcomingOrderFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { AvatarImage } from 'src/shared/design-system/Avatar';
import { minScreen } from 'src/shared/utils/StyledComponentUtils';
import styled from 'styled-components';
import { Colors, FontSize, FontWeight, ScreenWidth, Spacing } from 'web-common/src/shared/styles';

const StyledShefHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.GRAY_LITE_30};
  padding: ${Spacing.THREE_QUARTERS} ${Spacing.SINGLE};

  ${minScreen(ScreenWidth.SMALL)} {
    padding: ${Spacing.SINGLE} ${Spacing.SINGLE_HALF};
  }
`;

const StyledAvatarImage = styled(AvatarImage)`
  margin-right: ${Spacing.HALF};
`;

const ShefName = styled.span`
  font-size: ${FontSize.XS};
  font-weight: ${FontWeight.SEMI_BOLD};
`;

interface ShefHeaderProps {
  publicShef: UpcomingOrderFieldsFragment['publicShef'];
}

export const ShefHeader: React.FC<ShefHeaderProps> = ({ children, publicShef }): JSX.Element => {
  const { resizedImageUrl, publicName } = publicShef;
  return (
    <StyledShefHeader>
      <div>
        {resizedImageUrl && <StyledAvatarImage imageUrl={resizedImageUrl} size='32px' />}
        <ShefName>Shef {publicName}</ShefName>
      </div>
      {children}
    </StyledShefHeader>
  );
};
