import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

/**
 * Deprecated version of the left chevron. Use ChevronLeftV2 instead.
 * This chevron is no longer a part of the design system.
 */
export const ChevronLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <Path d='M17,5c0,0.38-0.15,0.77-0.44,1.06L10.62,12l5.94,5.94c0.59,0.59,0.59,1.54,0,2.12c-0.59,0.59-1.54,0.59-2.12,0l-7-7c-0.59-0.59-0.59-1.54,0-2.12l7-7c0.59-0.59,1.54-0.59,2.12,0C16.85,4.23,17,4.62,17,5z' />
    </SVG>
  );
};

export const ChevronLeftV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <Path d='M15 5L8 12L15 19' stroke='currentColor' strokeWidth='2' fill='none' />
    </SVG>
  );
};
