import React from 'react';
import { SVG } from 'web-common/src/shared/design-system/Utilities/SVG';

export interface BaseSVGProps extends React.SVGProps<SVGSVGElement> {
  squareSize?: number;
}

export const BaseIcon: React.FC<BaseSVGProps> = (props) => {
  const { height, width, squareSize, ...otherProps } = props;
  return (
    <SVG
      height={height || squareSize}
      width={width || squareSize}
      {...otherProps}
      viewBox={`0 0 ${squareSize} ${squareSize}`}
    />
  );
};

export const BaseIcon16: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return <BaseIcon squareSize={16} {...props} />;
};
