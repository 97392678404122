import { createContext, useState } from 'react';
import { ZipCodeSelectionModal } from './ZipCodeSelectionModal';

interface ShowOptions {
  isMealPlan?: boolean;
  redirectOnSubmit?: boolean;
  redirectUrl?: string;
  showCloseButton?: boolean;
}

interface ZipCodeSelectionModalContextProps {
  isZipCodeModalShowing: boolean;
  hideZipCodeModal: () => void;
  showZipCodeModal: (opts: ShowOptions) => void;
}

export const ZipCodeSelectionModalContext = createContext<ZipCodeSelectionModalContextProps>({
  isZipCodeModalShowing: false,
  hideZipCodeModal: () => {},
  showZipCodeModal: () => {},
});

const DEFAULT_OPTIONS: ShowOptions = {
  isMealPlan: false,
  redirectOnSubmit: true,
  redirectUrl: undefined,
  showCloseButton: true,
};

export const ZipCodeSelectionModalProvider: React.FC = ({ children }) => {
  const [isZipCodeModalShowing, setIsZipCodeModalShowing] = useState<boolean>(false);
  const [options, setOptions] = useState<ShowOptions>(DEFAULT_OPTIONS);

  const hideZipCodeModal = () => {
    setIsZipCodeModalShowing(false);
    setOptions(DEFAULT_OPTIONS);
  };

  const showZipCodeModal = (opts) => {
    setOptions(opts);
    setIsZipCodeModalShowing(true);
  };

  return (
    <ZipCodeSelectionModalContext.Provider value={{ isZipCodeModalShowing, hideZipCodeModal, showZipCodeModal }}>
      {children}
      <ZipCodeSelectionModal isShowing={isZipCodeModalShowing} hide={hideZipCodeModal} {...options} />
    </ZipCodeSelectionModalContext.Provider>
  );
};
