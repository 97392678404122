// eslint-disable-next-line no-restricted-imports
import { useLocation as useReactRouterLocation } from 'react-router';
import { ShefHistoryLocationState } from './useHistory';

/**
 * Hook that wraps react-router's useLocation with ShefHistoryLocationState
 * to improve typing throughout our app when using history location state.
 *
 * Prefer to use this hook over react-router's useLocation;
 *
 */
export const useLocation = () => {
  return useReactRouterLocation<ShefHistoryLocationState>();
};
