import { isDefined } from 'common/TypeUtilities';
import { sumBy } from 'lodash';
import Notifications from 'src/shared/Notifications';
import { RootState } from 'src/store';
import { cartSlice, editableOrderCartSlice } from 'src/store/cart';
import { queueAction } from 'src/store/cart/actions';
import { IncLineItemQuantityAction } from 'src/store/cart/types';
import { match } from 'ts-pattern';
import { selectLineItemAvailabilitiesById } from './selectors';
import { getAdjustedNumDishesAvailable } from './utils/capacity';

// validates that the user means to start a new order when modifying a food item's quantity
// for a delivery date other than what is in the cart
export const validateCartItemDeliveryDateMiddleware = (storeApi) => (next) => (action) => {
  const { cart }: RootState = storeApi.getState();

  if (!cart.isMultiCart) return next(action);

  const isCartIncAction = cartSlice.actions.incLineItemQuantity.match(action);
  const isCartDecAction = cartSlice.actions.decLineItemQuantity.match(action);
  const isCartSetAction = cartSlice.actions.setLineItemQuantity.match(action);
  const isNotQuantityModifiyingAction = !(isCartIncAction || isCartDecAction || isCartSetAction);
  if (isNotQuantityModifiyingAction) return next(action);

  const actionDeliveryDate = action.payload.deliveryDate;
  const isSameDeliveryDate = actionDeliveryDate === cart.deliveryDate;
  if (isSameDeliveryDate) return next(action);

  const hasNoItems = cart.lineItems.ids.length === 0;
  if (hasNoItems) return next(action);

  storeApi.dispatch(queueAction(action));
};

// validates that there is enough of an item before allowing the action to go through
export const validateFoodItemCapacityMiddleware = (storeApi) => (next) => (action) => {
  const isCartIncAction = cartSlice.actions.incLineItemQuantity.match(action);
  const isEditableOrderCartIncAction = editableOrderCartSlice.actions.incLineItemQuantity.match(action);

  if (isCartIncAction || isEditableOrderCartIncAction) {
    const state: RootState = storeApi.getState();
    const cartState = isCartIncAction ? state.cart : state.editableOrderCart;

    const incAction: IncLineItemQuantityAction = action;
    const { foodItem, location } = incAction.payload;

    const shef = cartState.shefs.entities[foodItem.userId];
    const { deliveryDate = '' } = action.payload;

    const lineItemQuantity = cartState.lineItems.entities[foodItem.id]?.quantity ?? 0;
    const foodItemsAvailability = foodItem.availability?.find((a) => a.availabilityDate.startsWith(deliveryDate));

    const lineItemAvailabilities = selectLineItemAvailabilitiesById(cartState);
    const { numRemaining, numAvailable: numDishesAvailable } = lineItemAvailabilities[foodItem.id] ?? {};

    const numLineItemAvailable = foodItemsAvailability ?? foodItem.capacity ?? 0;

    const numShefDishesAvailable = getAdjustedNumDishesAvailable({
      shefAvailability: shef?.availability || [],
      rawOrderData: cartState.rawOrderData,
      deliveryDate,
    });

    const lineItems = Object.values(cartState.lineItems.entities);
    const shefLineItems = lineItems.filter(isDefined).filter((li) => li.shefId === foodItem.userId);
    const shefLineItemQuantity = sumBy(shefLineItems, 'quantity');

    const itemIsMaxedOut =
      numRemaining !== undefined && numRemaining !== null
        ? numRemaining <= 0
        : lineItemQuantity >= numLineItemAvailable;
    const shefIsMaxedOut = shefLineItemQuantity >= numShefDishesAvailable;

    const capacityErrorMsg = match({ itemIsMaxedOut, shefIsMaxedOut })
      .with({ shefIsMaxedOut: true }, () => `You can't add any more dishes for this shef.`)
      .with({ itemIsMaxedOut: true }, () => `You have added the max amount of this dish to your cart.`)
      .otherwise(() => '');

    if (capacityErrorMsg) {
      console.error('User could not add item to cart', {
        now: new Date().toISOString(),
        capacityErrorMsg,
        shefIsMaxedOut,
        itemIsMaxedOut,
        numLineItemAvailable,
        numDishesAvailable,
        shef,
        deliveryDate,
        action,
        lineItemQuantity,
        foodItem,
        foodItemsAvailability,
        shefLineItemQuantity,
        rawOrderData: cartState.rawOrderData,
        isMultiCart: cartState.isMultiCart,
      });

      if (location === 'dish-first-explore-page') {
        Notifications.error(capacityErrorMsg);
      }

      return;
    }
  }

  return next(action);
};
