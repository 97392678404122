import React from 'react';
import styled from 'styled-components';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

const StyledPath = styled(Path)`
  stroke-width: 2;
  stroke-linecap: round;
  fill: none;
`;

export const Back = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={10} width={18} viewBox={'1 0 11 18'} fill='none' {...props}>
      <StyledPath d='M9 1L1.17678 8.82322C1.07915 8.92085 1.07915 9.07915 1.17678 9.17678L9 17' stroke='currentColor' />
    </SVG>
  );
};
