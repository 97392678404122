import { useCallback, useEffect, useRef, useState } from 'react';
import { animationDuration, LightboxProps } from '../consts';

export const useLightbox = (props: LightboxProps) => {
  const { defaultShow = false, onShow, ...otherProps } = props;
  const [isShown, setIsShown] = useState(defaultShow);
  const [isShowing, setIsShowing] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const shownTimeout = useRef<number>();

  const handleClickContainer = useCallback(() => {
    if (!isShown) {
      onShow?.();
      setIsShowing(true);
      shownTimeout.current = window.setTimeout(() => {
        setIsShown(true);
        setIsShowing(false);
      }, animationDuration);
    } else {
      setIsHiding(true);
      shownTimeout.current = window.setTimeout(() => {
        setIsShown(false);
        setIsHiding(false);
      }, animationDuration);
    }
  }, [isShown, onShow]);

  useEffect(() => {
    return () => clearTimeout(shownTimeout.current);
  }, []);

  return {
    ...otherProps,
    isShown,
    isShowing,
    isHiding,
    isVisible: isShowing || isHiding || isShown,
    handleClickContainer,
    backdropProps: {
      isShown,
      isShowing,
      isHiding,
      onClick: handleClickContainer,
    },
    containerProps: {
      isShown,
      onClick: handleClickContainer,
    },
    imageContainerProps: {},
    lightboxContainerProps: {
      isShown,
      isShowing,
      isHiding,
    },
  };
};
