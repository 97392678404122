import { ClientEvents } from 'common/events/ClientEvents';
import { parse as parseQueryString } from 'query-string';
import React from 'react';
import { Route, RouteChildrenProps, RouteProps } from 'react-router';
import { useSearchQueryReducer } from 'src/pages/consumer/components/search/store/hooks/useSearchQuery';
import { withErrorBoundary } from 'src/shared/errors/withErrorBoundary';
import { useTracker } from 'src/shared/hooks/useTracker';
import { withLoading } from 'src/shared/routing/withLoading';
import { getPreferredColorTheme } from 'src/shared/utils/ColorThemeUtils';
import { useIsOnSearchPage } from 'src/shared/utils/SearchUtils';
import { LazyComponent } from 'src/util/retry';
import { usePrevious } from '../shared/hooks/usePreviousHook';

export const useRouteTracking = (location: RouteChildrenProps['location'], match: RouteChildrenProps['match']) => {
  const tracker = useTracker();
  const prevLocation = usePrevious(location);
  const preferredColorTheme = getPreferredColorTheme();
  const { isOnSearchPage, isOnSearchPageMobile } = useIsOnSearchPage();
  const { changeSearchQuery, searchQuery: query } = useSearchQueryReducer();

  React.useEffect(() => {
    if (!prevLocation || location.pathname !== prevLocation.pathname || location.search !== prevLocation.search) {
      const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      tracker.track(ClientEvents.PAGE_VIEW, {
        route: location.pathname + location.search,
        queryParams: location.search ? parseQueryString(location.search) : null,
        pattern: match?.path ?? null,
        params: match?.params ?? null,
        preferredColorTheme,
        viewportDimensions: {
          widthPixels: viewWidth,
          heightPixels: viewHeight,
        },
      });
    }
    if (!isOnSearchPage && !isOnSearchPageMobile && location.pathname !== prevLocation?.pathname && query !== '') {
      changeSearchQuery('');
    }
  }, [
    tracker,
    location.pathname,
    location.search,
    prevLocation,
    match?.path,
    match?.params,
    preferredColorTheme,
    isOnSearchPage,
    isOnSearchPageMobile,
    query,
    changeSearchQuery,
  ]);
};

type RoutePropsOnlyComponent = Omit<RouteProps, 'render' | 'children'> & {
  component: React.ComponentType<any> | LazyComponent<any>;
};

const withRouteTracking: (Base: React.ComponentType<RouteChildrenProps>) => React.FC<RouteChildrenProps> = (
  Base: React.ComponentType<RouteChildrenProps>
) => {
  return (props: RouteChildrenProps) => {
    useRouteTracking(props.location, props.match);
    return <Base {...props} />;
  };
};

export const TrackedRouteWithErrorBoundary: React.FC<RoutePropsOnlyComponent> = (props) => {
  const Component = React.useMemo(
    () => withRouteTracking(withErrorBoundary(withLoading(props.component))),
    [props.component]
  );
  return <Route {...props} component={Component} />;
};
