import React from 'react';
import styled, { css } from 'styled-components';

type SVGProps = {
  autoViewBox?: boolean;
  /** Additional style class. */
  className?: string;
  /** Height of the SVG. */
  height?: string | number;
  /** WIdth of the SVG. */
  width?: string | number;
  /** Shortcut for Height and Width. */
  size?: string | number;
  /** Viewbox of the SVG */
  viewBox?: string;
  /** Fill of the SVG */
  fill?: string;
  /** Indicates how an element with a viewBox fits into a viewport with a different aspect ratio  */
  preserveAspectRatio?: string;
};

const Container = styled.svg`
  -webkit-touch-callout: none;
`;

export const Circle = styled.circle`
  ${({ fill }) =>
    css`
      fill: ${fill ?? 'currentColor'};
    `}
`;

export const Path = styled.path`
  ${({ fill }) =>
    css`
      fill: ${fill ?? 'currentColor'};
    `}
`;

export const Rect = styled.rect`
  ${({ fill }) =>
    css`
      fill: ${fill ?? 'currentColor'};
    `}
`;

/**
 * @deprecated Please use web-common version
 */
export const SVG: React.FC<SVGProps> = (props) => {
  const { autoViewBox = true, children, className, height, size, viewBox, width, ...otherProps } = props;
  const heightValue = size || height;
  const widthValue = size || width;
  const viewBoxValue = viewBox || (autoViewBox ? `0 0 ${heightValue} ${widthValue}` : undefined);

  return (
    <Container
      className={className}
      height={heightValue}
      width={widthValue}
      viewBox={viewBoxValue}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}>
      {children}
    </Container>
  );
};
