import React from 'react';
import { DynamicImage } from 'src/shared/design-system/DynamicImage';
import styled from 'styled-components';
import { BorderRadius, Colors, Spacing } from 'web-common/src/shared/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100px;
  width: calc(100% - 48px); // HACK - keep width correct
  background-color: ${Colors.PLUM_DARK_10};
  border-radius: ${BorderRadius.S};
  overflow: hidden;
`;

const LogoImg = styled.img`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 160px;

  @media (min-width: 375px) {
    top: 15px;
    left: 16px;
    width: 180px;
  }

  @media (min-width: 400px) {
    top: 10px;
    left: 16px;
    width: 200px;
  }
`;

const BackgroundImage = styled(DynamicImage)`
  position: absolute;
  z-index: 1;

  border-radius: 100%;
  object-fit: cover;
  object-position: top;

  height: 140px;
  width: 140px;
  right: -30px;
  top: -60px;

  @media (min-width: 420px) {
    // To match designs
    height: 160px;
    width: 160px;
    right: -30px;
    top: -60px;
  }
`;

const CtaBannerContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  background-color: ${Colors.JACKFRUIT};
  border-radius: 0 0 ${BorderRadius.S} ${BorderRadius.S};
  width: 100%;
`;
const CtaBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;

  width: 100%;
  justify-content: flex-start;
  padding: 0 ${Spacing.SINGLE};

  @media (min-width: 420px) {
    width: calc(100% - 100px);
    justify-content: center;
    padding: unset;
  }

  @media (min-width: 520px) {
    width: 100%;
    justify-content: center;
    padding: unset;
  }
`;

interface IProps {
  className?: string;
  backgroundImage: string;
  logos: string;
  ctaText: string;
}

// 3024×4032
export const NyeshaStyleLoginPromoBanner: React.FC<IProps> = ({ className, backgroundImage, logos, ctaText }) => {
  return (
    <Container className={className}>
      <BackgroundImage src={backgroundImage} sourceWidth={300} sourceHeight={400} />
      <LogoImg src={logos} />
      <CtaBannerContainer>
        <CtaBanner>
          <span className='text-[11px] font-semibold text-neutral-800'>{ctaText}</span>
        </CtaBanner>
      </CtaBannerContainer>
    </Container>
  );
};
