import { SessionStorageKeys } from 'common/storage/constants';
import { Action, Location } from 'history';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, RouteChildrenProps, useLocation } from 'react-router'; // eslint-disable-line no-restricted-imports
import { useSessionStorage } from '../shared/storage/Storage';

const createHistoryStack = (initialStack?: Location[]) => {
  const stack: Location[] = initialStack ? [...initialStack] : [];

  const push = (val: Location) => stack.push(val);
  const pop = () => stack.pop();
  const peek = () => (stack.length - 1 >= 0 ? stack[stack.length - 1] : undefined);
  const find = (val: Location) => stack.findIndex(({ key }) => key === val.key);
  const includes = (val: Location) => find(val) > -1;
  const popUntil = (val: Location) => stack.splice(find(val) + 1);
  const clone = () => [...stack];
  const getValue = () => stack;
  const size = () => stack.length;
  return {
    push,
    pop,
    peek,
    includes,
    popUntil,
    clone,
    getValue,
    size,
  };
};

interface IHistoryContext {
  matchesRoute: (route: string) => boolean;
  previousUrl?: string;
}

export const HistoryContext = React.createContext<IHistoryContext>({
  matchesRoute: (_: string) => true,
});

const useLocationAndAction = (history: RouteChildrenProps['history']): [Location, Action | null] => {
  const startingLocation = useLocation();

  const [location, setLocation] = useState<Location>(startingLocation);
  const [action, setAction] = useState<Action | null>(null);

  const updateLocation = useCallback(
    (newLocation: Location, action: Action) => {
      if (newLocation.key === location.key) return;
      setLocation(newLocation);
      setAction(action);
    },
    [setLocation, setAction, location]
  );

  useEffect(() => {
    return history.listen(updateLocation); // clean up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [location, action];
};

interface IProps {
  history: RouteChildrenProps['history'];
}

export const HistoryProvider: React.FC<IProps> = ({ history, children }) => {
  const [location, action] = useLocationAndAction(history);

  const [sessionHistoryStack, setSessionHistoryStack] = useSessionStorage<Location[]>(
    SessionStorageKeys.HISTORY_STACK,
    [location]
  );
  const [historyStackState, setHistoryStackState] = useState(sessionHistoryStack);

  const setHistoryStack = useCallback(
    (newStack: Location[]) => {
      // i doubt someone hits back more than 10 times but why not
      const limitedStack = newStack.slice(-1000);
      setHistoryStackState(limitedStack);
      setSessionHistoryStack(limitedStack);
    },
    [setSessionHistoryStack]
  );

  /*
    Whenever the router changes location, we check to see if it's in our history.
    If so, we pop back to that location (handles things like clicking
    back and using the back button dropdown).
    If not in history, we push the new location onto the stack
   */
  useEffect(() => {
    const stack = createHistoryStack(historyStackState);

    if (stack.peek()?.key === location.key) return;

    // Covers usage of back button
    if (stack.includes(location)) {
      stack.popUntil(location);
      setHistoryStack(stack.getValue());
      return;
    }

    // Only covering actions that are not back button here
    switch (action) {
      case 'REPLACE': // Replacing current location
        stack.pop();
        stack.push(location);
        setHistoryStack(stack.getValue());
        break;
      case 'PUSH': // Navigating to new page
      case 'POP': // Clicking browser forward button (->)
        // POP case is for
        stack.push(location);
        setHistoryStack(stack.getValue());
        break;
      default:
        break;
    }
  }, [location, action, setHistoryStack, historyStackState]);

  const previousLocation = useMemo(() => {
    const stack = createHistoryStack(historyStackState);
    stack.pop();
    return stack.peek();
  }, [historyStackState]);

  const previousUrl = useMemo(() => {
    if (previousLocation) {
      return previousLocation.pathname + previousLocation.search + previousLocation.hash;
    }
    return undefined;
  }, [previousLocation]);

  const matchesRoute = useCallback(
    (route: string) => {
      if (previousLocation) {
        const match = matchPath(previousLocation.pathname, route);
        return Boolean(match);
      }
      return false;
    },
    [previousLocation]
  );

  const value = useMemo(
    () => ({
      previousUrl,
      matchesRoute,
    }),
    [matchesRoute, previousUrl]
  );

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
};

export const usePreviousPage = () => {
  const { previousUrl, matchesRoute } = useContext(HistoryContext);

  return {
    previousUrl,
    matchesRoute,
  };
};
