import React from 'react';

export const StepByStep: React.FC<{
  activeStep: number;
  className?: string;
}> = ({ activeStep, className, children }) => {
  const numSteps = React.Children.count(children);
  if (activeStep < 0 || activeStep > numSteps) {
    throw new Error('StepByStep received invalid activeStep, needs to be between 0 and Num Children');
  }

  return (
    <div className={className}>{React.Children.map(children, (child, idx) => (idx === activeStep ? child : null))}</div>
  );
};
