import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const HeartOutline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <Path d='M19.6057 6.55141L18.9599 7.17823L18.9599 7.17821L19.6057 6.55141ZM19.6057 13.4572L18.9592 12.8311L18.9593 12.831L19.6057 13.4572ZM12.5049 20.7888L11.8564 20.1648L11.8584 20.1627L12.5049 20.7888ZM11.5029 20.7888L12.1487 20.162L12.1492 20.1626L11.5029 20.7888ZM4.39494 13.4642L3.74913 14.0911L3.74911 14.0911L4.39494 13.4642ZM11.1859 6.55845L10.5434 7.18871L10.5401 7.18529L11.1859 6.55845ZM12.0005 7.38884L12.6457 8.01636L12.0033 8.67683L11.358 8.0191L12.0005 7.38884ZM12.8151 6.55134L13.461 7.17815L13.4603 7.17885L12.8151 6.55134ZM16.2102 4.21771C17.6746 4.21771 19.1442 4.78355 20.2516 5.9246L18.9599 7.17821C18.2075 6.40297 17.2114 6.01771 16.2102 6.01771V4.21771ZM20.2516 5.92458C22.4509 8.19062 22.4479 11.8169 20.2521 14.0834L18.9593 12.831C20.4798 11.2614 20.4804 8.74484 18.9599 7.17823L20.2516 5.92458ZM20.2522 14.0833L13.1514 21.415L11.8584 20.1627L18.9592 12.8311L20.2522 14.0833ZM13.1535 21.4129C12.8509 21.7274 12.4341 21.9 12.0039 21.9V20.1C11.954 20.1 11.8995 20.12 11.8564 20.1648L13.1535 21.4129ZM12.0039 21.9C11.5734 21.9 11.1583 21.7266 10.8565 21.4151L12.1492 20.1626C12.1088 20.1208 12.056 20.1 12.0039 20.1V21.9ZM10.8571 21.4157C8.48841 18.9756 6.11726 16.5309 3.74913 14.0911L5.04075 12.8374C7.41048 15.2789 9.77903 17.7209 12.1487 20.162L10.8571 21.4157ZM3.74911 14.0911C1.55031 11.8256 1.55028 8.197 3.74913 5.93161L5.04075 7.18529C3.51976 8.75231 3.51973 11.2703 5.04077 12.8374L3.74911 14.0911ZM3.74913 5.93161C5.96255 3.6512 9.61817 3.65106 11.8317 5.93161L10.5401 7.18529C9.0336 5.63319 6.54717 5.63328 5.04075 7.18529L3.74913 5.93161ZM11.8284 5.92819L12.643 6.75858L11.358 8.0191L10.5434 7.1887L11.8284 5.92819ZM11.3554 6.76133L12.17 5.92382L13.4603 7.17885L12.6457 8.01636L11.3554 6.76133ZM12.1693 5.92452C13.2767 4.78349 14.7458 4.21771 16.2102 4.21771V6.01771C15.2091 6.01771 14.2134 6.40284 13.461 7.17815L12.1693 5.92452Z' />
    </SVG>
  );
};

export const Heart = (props: React.SVGProps<SVGSVGElement> & { outline?: boolean }) => {
  const { outline, ...svgProps } = props;
  return outline ? (
    <HeartOutline {...svgProps} />
  ) : (
    <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...svgProps}>
      <Path d='M16.6781 4.23537C18.0478 4.23537 19.4176 4.76375 20.4508 5.82836C22.5174 7.95761 22.5154 11.3703 20.4508 13.5015L12.561 21.6477C12.4169 21.7976 12.2156 21.8824 12.0043 21.8824C11.7941 21.8824 11.5928 21.7976 11.4476 21.6477C8.81524 18.9359 6.18208 16.2211 3.54993 13.5093C1.48335 11.38 1.48335 7.9653 3.54993 5.83618C5.61651 3.70706 9.02876 3.70694 11.0955 5.83618L12.0006 6.75884L12.9057 5.82829C13.939 4.76365 15.3083 4.23537 16.6781 4.23537Z' />
    </SVG>
  );
};
