import { StorageKeys } from 'common/storage/constants';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { HelpButtonState, SetHasSeenNewUserEducationModalAction } from './types';

export const showHelpButton = (state: HelpButtonState) => {
  return { ...state, isShowing: true };
};

export const hideHelpButton = (state: HelpButtonState) => {
  return { ...state, isShowing: false };
};

export const setHasSeenNewUserEducationModal = (
  state: HelpButtonState,
  { payload }: SetHasSeenNewUserEducationModalAction
) => {
  const storage = getBrowserStorage();
  const hasSeenNewUserEducationModal = payload.hasSeenModal ?? false;

  storage.setItem(StorageKeys.NEW_USER_EDUCATION_MODAL, JSON.stringify(hasSeenNewUserEducationModal));
  return { ...state, hasSeenNewUserEducationModal };
};
