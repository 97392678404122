import { Routes } from 'src/shared/Routes';

// React Hook Form consts
export const mode = 'all';
export const reValidateMode = 'onChange';

// Human readable step names for breadcrumbs
export enum PreferenceFormSteps {
  SETTINGS = 'Settings',
  DELIVERY_DAY = 'DeliveryDay',
  DIET = 'Diet',
  TASTE = 'Taste',
  CUISINE = 'Cuisine',
}

// Strings for query param navigation
export enum PreferenceFormUrls {
  SETTINGS = 'settings',
  DELIVERY_DAY = 'deliveryDay',
  DIET = 'diet',
  TASTE = 'taste',
  CUISINE = 'cuisine',
}

type StepDetails = {
  name: PreferenceFormSteps;
  url: PreferenceFormUrls;
};

export const ANALYTICS_STEPS = {
  [PreferenceFormUrls.SETTINGS]: 'customize_your_plan',
  [PreferenceFormUrls.DELIVERY_DAY]: 'choose_your_delivery_day',
  [PreferenceFormUrls.DIET]: 'tell_us_about_your_diet',
  [PreferenceFormUrls.TASTE]: 'your_taste_preferences',
  [PreferenceFormUrls.CUISINE]: ' your_cuisine_preferences',
};

// This is the order of the steps. If you need to adjust the order, do it here and it will reflect where relevant
export const STEPS: StepDetails[] = [
  { name: PreferenceFormSteps.CUISINE, url: PreferenceFormUrls.CUISINE },
  { name: PreferenceFormSteps.DIET, url: PreferenceFormUrls.DIET },
  { name: PreferenceFormSteps.TASTE, url: PreferenceFormUrls.TASTE },
  { name: PreferenceFormSteps.SETTINGS, url: PreferenceFormUrls.SETTINGS },
  { name: PreferenceFormSteps.DELIVERY_DAY, url: PreferenceFormUrls.DELIVERY_DAY },
];

export const MEAL_PLANS_PREFERENCES_FIRST_STEP = `${Routes.CONSUMER_MEAL_PLANS_PREFERENCES}?step=${STEPS[0].url}`;

// Given a url string, return the index of the corresponding step (for use in next/prev callbacks)
export const getStepIndexByUrl = (url: string) => STEPS.findIndex((step) => step.url === decodeURIComponent(url));

// Given a url string, return the name of the corresponding step (for use in Breadcrumbs)
export const getStepNameByUrl = (url: string) => STEPS.find((step) => step.url === decodeURIComponent(url))?.name;
export const CUISINES = [
  'INDIAN',
  'AMERICAN',
  'SOUTHEAST_ASIAN',
  'MEDITERRANEAN',
  'LATIN_AMERICAN',
  'MIDDLE_EASTERN',
  'CHINESE',
  'PAKISTANI',
  'SOUTHERN',
];

export const SUBCUISINES_BY_REGION = {
  1: [
    'NORTH_INDIAN',
    'PUNJABI',
    'SOUTH_INDIAN',
    'MAHARASHTRIAN',
    'WEST_INDIAN',
    'DELHI',
    'KERALA',
    'MUGHLAI',
    'ANDHRA',
    'BENGALI',
    'GUJARATI',
    'KARNATAKA',
  ],
  2: ['NORTH_INDIAN', 'PUNJABI', 'SOUTH_INDIAN', 'KERALA', 'MUGHLAI'],
  5: ['NORTH_INDIAN', 'PUNJABI', 'BENGALI', 'EAST_INDIAN', 'MAHARASHTRIAN', 'SOUTH_INDIAN'],
  6: [
    'NORTH_INDIAN',
    'SOUTH_INDIAN',
    'PUNJABI',
    'KARNATAKA',
    'TAMILIAN',
    'WEST_INDIAN',
    'MUGHLAI',
    'BENGALI',
    'EAST_INDIAN',
    'MAHARASHTRIAN',
    'MUMBAI',
  ],
  8: ['NORTH_INDIAN', 'PUNJABI', 'SOUTH_INDIAN', 'GUJARATI', 'WEST_INDIAN', 'SOUTHEAST_ASIAN'],
  13: ['NORTH_INDIAN', 'PUNJABI', 'SOUTH_INDIAN', 'KERALA', 'MUGHLAI'],
};
