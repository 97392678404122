import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { Colors, Transition } from 'web-common/src/shared/styles';

export type ClickableProps = {
  /** Additional style class. */
  className?: string;
  /** If clickable element is disabled. (Nullifies the onClick callback.) */
  disabled?: boolean;
  /** Creates an <a> tag an passes the href url value. */
  href?: string;
  /** Id of the clickable. */
  id?: string;
  /** Style as clickable but don't use button or anchor tag. */
  nonClickable?: boolean;
  /** Callback when clickable is clicked. */
  onClick?: () => any;
  /** When `href` is passed, set this flag to open link in a new window. */
  openInNewWindow?: boolean;
  /** Styles it as a link. */
  styleAsLink?: boolean;
  /** Path to another page in the app. Create a React Router Link component instead of <a>. */
  to?: string;
};

interface ClickableStyles extends ClickableProps {
  $styleAsLink?: boolean;
}

const getCursorStyle = ({ disabled, onClick }: ClickableStyles): string =>
  match(disabled)
    .with(true, () => 'not-allowed')
    .otherwise(() => (onClick ? 'pointer' : 'default'));

const getLinkStyles = ({ $styleAsLink }: ClickableStyles): string =>
  match($styleAsLink)
    .with(
      true,
      () => `
        color: ${Colors.AQUA};
        text-decoration: underline;
        transition: color ${Transition.Time.BASE};

        &:hover {
          color: ${Colors.AQUA_DARK_20};
        }
        &:hover {
          color: ${Colors.AQUA_DARK_20};
        }
      `
    )
    .otherwise(() => '');

const NonClickable = styled.div<ClickableProps>`
  ${getLinkStyles}
`;

const Button = styled.button<ClickableProps>`
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: ${getCursorStyle};
  ${getLinkStyles}
`;

const Link = styled.a<ClickableProps>`
  outline: none;
  cursor: ${getCursorStyle};
  ${getLinkStyles}
`;

const ReactLink = styled(ReactRouterLink)`
  cursor: ${getCursorStyle};
  ${getLinkStyles}
`;

export const Clickable: React.FC<ClickableProps> = (props) => {
  const {
    className,
    children,
    disabled,
    href,
    id,
    nonClickable,
    onClick,
    openInNewWindow,
    styleAsLink = false,
    to,
  } = props;

  const sharedProps = {
    className,
    children,
    disabled,
    id,
    onClick: disabled ? undefined : onClick,
    ...(styleAsLink ? { $styleAsLink: true } : {}),
    ...(props['data-cy'] ? { 'data-cy': props['data-cy'] } : {}),
  };

  if (to) {
    return <ReactLink {...sharedProps} to={to} target={openInNewWindow ? '_blank' : '_self'} />;
  }
  if (href) {
    return <Link {...sharedProps} href={href} target={openInNewWindow ? '_blank' : '_self'} />;
  }
  return nonClickable ? <NonClickable {...sharedProps} /> : <Button {...sharedProps} />;
};
