import { formatGqlErrors } from 'common/GqlUtilities';
import { selectLineItems, selectMultiCartDeliveryDateTime } from 'src/store/cart/selectors';
import { cartSaveItemsMutation } from 'src/store/cart/utils/cartMutations';
import { fetchCart } from 'src/store/cart/utils/cartQueries';
import { CreateCartEffect } from './listeners';

export const syncLineItemQuantityWithServer: CreateCartEffect =
  ({ gqlClient }) =>
  async (action, listenerApi) => {
    const { cart } = listenerApi.getState();
    if (!cart.userLoggedIn) return;

    // Refresh items in the cart for the shef, which may have changed as a result of the update.
    const lineItems = selectLineItems(cart);
    const shefId = action.payload.foodItem.userId;
    const existingQuantities = lineItems
      .filter((lineItem) => cart.isMultiCart || lineItem.shefId === shefId)
      .map((lineItem) => {
        return { foodItemId: lineItem.foodItemId, quantity: lineItem.quantity };
      });
    const existingCartFoodItemIds = new Set(existingQuantities.map((item) => item.foodItemId));
    const deliveryDate = selectMultiCartDeliveryDateTime(cart).toISO();

    try {
      // TODO: Avoid needing two calls for this.
      const { data } = await fetchCart({ gqlClient, zipCode: cart.zipCode });
      const removeItems =
        data?.carts.foodItems
          .filter(
            (item) => (cart.isMultiCart || item.shefId === shefId) && !existingCartFoodItemIds.has(item.foodItemId)
          )
          .map((item) => ({ foodItemId: item.foodItemId, quantity: 0 })) ?? [];
      const cartLineItemInputs = [...existingQuantities, ...removeItems];

      const { errors } = await cartSaveItemsMutation({ gqlClient, cartLineItemInputs, deliveryDate });
      // FUTURE TODO: how to handle errors - likely need to reset state...
      if (errors?.length) return console.error(formatGqlErrors(errors));
    } catch (err) {
      console.error(err);
    }
  };
