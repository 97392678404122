import * as Sentry from '@sentry/react';
import { webStorageClear, webStorageRemoveItem, webStorageSetItem } from '@shef/native-bridge';
import { SessionStorageKeys } from 'common/storage/constants';
import { NativeAppMessenger } from 'src/mobile-app/NativeAppMessenger';
import { IStorage, storages } from '../storage/Storage';

const { setItem: originalSetItem, clear: originalClear, removeItem: originalRemoveItem } = Storage.prototype;

/**
 * Set of keys that shouldn't be synced with the native app.
 *   - '__test' is called by local-storage-fallback for testing purposes.
 *   - SessionStorageKeys.HISTORY_STACK shouldn't be the same across webviews. We have our own history stack in the app.
 */
const INVALID_KEYS_TO_STORE_IN_APP = new Set(['__test', SessionStorageKeys.HISTORY_STACK]);

/**
 * Initializes localStorage and sessionStorage with initial state in window.App.storage.
 * This should run before we try to read from local or session storage.
 */
export const initializeStorageStateForNativeApp = () => {
  const initialStorageState = window.App?.storage;

  if (initialStorageState) {
    const { local, session } = initialStorageState;

    Object.entries(local).forEach(([key, value]) => {
      originalSetItem.call(storages.local, key, value);
    });

    Object.entries(session).forEach(([key, value]) => {
      originalSetItem.call(storages.session, key, value);
    });
  }
};

const getStorageType = (storage: IStorage): 'localStorage' | 'sessionStorage' => {
  if (storage === storages.local) {
    return 'localStorage';
  }
  if (storage === storages.session) {
    return 'sessionStorage';
  }

  throw new Error(`Unknown storage type: ${storage}`);
};

const isValidItemToStoreInApp = (key: string) => {
  return !INVALID_KEYS_TO_STORE_IN_APP.has(key);
};

/**
 * Ovewrites methods in localStorage and sessionStorage to support syncing with the native app.
 */
export const overwriteStorageMethodsForNativeApp = (nativeAppMessenger: NativeAppMessenger) => {
  function getErrorHandler(message: string, extras?: object) {
    return (error: unknown) => Sentry.captureException(error, { extra: { message, ...extras } });
  }

  function clearOverride(this: IStorage) {
    originalClear.call(this);

    const storageType = getStorageType(this);

    nativeAppMessenger
      .sendRequestWithRetry(webStorageClear, { storageType })
      .catch(getErrorHandler('Failed to clear storage', { storageType }));
  }

  function removeItemOverride(this: IStorage, key: string) {
    originalRemoveItem.call(this, key);

    const storageType = getStorageType(this);

    if (isValidItemToStoreInApp(key)) {
      nativeAppMessenger
        .sendRequestWithRetry(webStorageRemoveItem, { storageType, key })
        .catch(getErrorHandler('Failed to remove web storage item', { storageType, key }));
    }
  }

  function setItemOverride(this: IStorage, key: string, value: string) {
    originalSetItem.call(this, key, value);

    const storageType = getStorageType(this);
    if (isValidItemToStoreInApp(key)) {
      nativeAppMessenger
        .sendRequestWithRetry(webStorageSetItem, { storageType, key, value })
        .catch(getErrorHandler('Failed to set web storage item', { storageType, key, value }));
    }
  }

  Storage.prototype.clear = clearOverride;
  Storage.prototype.removeItem = removeItemOverride;
  Storage.prototype.setItem = setItemOverride;
};
