import { useEffect, useRef, useState } from 'react';
import {
  CanScroll,
  checkCanScroll,
  checkCanScrollHorizontal,
  checkCanScrollVertical,
  ScrollHorizontalProps,
  ScrollProps,
  ScrollVerticalProps,
} from 'src/shared/design-system/shared/utils/checkCanScroll';

export type { ScrollHorizontalProps, ScrollProps, ScrollVerticalProps, CanScroll };

export const useCanScrollBase = (handleScroll: () => void) => {
  const mountTimeout = useRef(0);
  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Check on mount
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleScroll);
    window.addEventListener('popstate', handleScroll);
    // eslint-disable-next-line functional/immutable-data -- Loading timeout
    mountTimeout.current = Number(setTimeout(handleScroll, 200));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Check on resize
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(mountTimeout.current);
      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('popstate', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Clear on unmount
  }, []);
};

export const useCanScrollVertical = (
  elementRef: React.RefObject<HTMLDivElement>
): ScrollVerticalProps & { handleScroll: () => void } => {
  const [up, setUp] = useState<boolean>(false);
  const [down, setDown] = useState<boolean>(false);

  const handleScroll = () => {
    const scrollProps = checkCanScrollVertical(elementRef);
    setUp(scrollProps[CanScroll.UP]);
    setDown(scrollProps[CanScroll.DOWN]);
  };

  useCanScrollBase(handleScroll);

  return { [CanScroll.UP]: up, [CanScroll.DOWN]: down, handleScroll };
};

export const useCanScrollHorizontal = (
  elementRef: React.RefObject<HTMLDivElement>
): ScrollHorizontalProps & { handleScroll: () => void } => {
  const [left, setLeft] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);

  const handleScroll = () => {
    const scrollProps = checkCanScrollHorizontal(elementRef);
    setLeft(scrollProps[CanScroll.LEFT]);
    setRight(scrollProps[CanScroll.RIGHT]);
  };

  useCanScrollBase(handleScroll);

  return { [CanScroll.LEFT]: left, [CanScroll.RIGHT]: right, handleScroll };
};

export const useCanScroll = (
  elementRef: React.RefObject<HTMLDivElement>
): ScrollProps & { handleScroll: () => void } => {
  const [up, setUp] = useState<boolean>(false);
  const [down, setDown] = useState<boolean>(false);
  const [left, setLeft] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);

  const handleScroll = () => {
    const scrollProps = checkCanScroll(elementRef);
    setUp(scrollProps[CanScroll.UP]);
    setDown(scrollProps[CanScroll.DOWN]);
    setLeft(scrollProps[CanScroll.LEFT]);
    setRight(scrollProps[CanScroll.RIGHT]);
  };

  useCanScrollBase(handleScroll);

  return { [CanScroll.UP]: up, [CanScroll.DOWN]: down, [CanScroll.LEFT]: left, [CanScroll.RIGHT]: right, handleScroll };
};
