import { EquallyDistributedTieredProgressBarProps, TierType } from '../../types';

export const useEquallyDistributed = (props: EquallyDistributedTieredProgressBarProps) => {
  const { tierContent, includeZero = false, percent, tiers, totalPercent = 100, ...otherProps } = props;
  const numberOfTiers = tiers.length;

  const formattedTiers = tiers.map(
    (tier: number | TierType): TierType => (typeof tier === 'number' ? { percent: tier } : tier)
  );
  const distributedTiers = formattedTiers.map((tier: TierType, index: number): TierType => {
    return {
      ...tier,
      percent: (100 / numberOfTiers) * (index + 1),
    };
  });

  const numberOfCompletedTiers = formattedTiers.filter((tier) => percent > tier.percent).length;
  const percentPerTier = 100 / numberOfTiers;
  const completedPercent = percentPerTier * numberOfCompletedTiers;
  const previousTierPercent = formattedTiers[numberOfCompletedTiers - 1]
    ? formattedTiers[numberOfCompletedTiers - 1].percent
    : 0;
  const currentTierPercent = formattedTiers[numberOfCompletedTiers]
    ? formattedTiers[numberOfCompletedTiers].percent
    : 100;
  const currentPercent =
    ((percent - previousTierPercent) / (currentTierPercent - previousTierPercent)) * percentPerTier;
  const percentComplete = Math.min(completedPercent + currentPercent, 100);

  return {
    ...otherProps,
    tierContent,
    tiers: includeZero ? [{ content: tierContent, percent: 0 }, ...distributedTiers] : distributedTiers,
    percent: percentComplete,
    totalPercent,
  };
};
