import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Serving1Portion = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <title>Single serving size</title>
      <desc>A single bowl</desc>
      <Path
        d='M8 3C5.09114 3 2 3.87696 2 5.50083C2 7.12471 2.62751 8.68078 3.76653 9.75003C4.33217 10.2813 5.00497 10.6881 5.74627 10.9593V12.6099C5.74627 12.8244 5.91972 12.9989 6.13294 12.9989H9.86595C10.0792 12.9989 10.2526 12.8244 10.2526 12.6099V10.9593C10.9939 10.6881 11.6667 10.2813 12.2324 9.75003C13.3714 8.68078 13.9989 7.17139 13.9989 5.50083C13.9989 3.83028 10.9089 3 8 3ZM8 3.77804C11.2381 3.77804 13.2267 4.78171 13.2267 5.50083C13.2267 6.21996 11.2381 7.22474 8 7.22474C4.76192 7.22474 2.77334 6.22107 2.77334 5.50083C2.77334 4.78059 4.76192 3.77804 8 3.77804ZM9.47929 12.222H6.51961V11.1894C6.99466 11.2972 7.4907 11.3517 7.9989 11.3517C8.50709 11.3517 9.00313 11.2961 9.47818 11.1894V12.222H9.47929ZM8 10.5736C5.47671 10.5736 3.53231 9.09314 2.95231 6.89908C4.09133 7.62265 6.08544 8.00278 8 8.00278C9.91456 8.00278 11.9087 7.62265 13.0477 6.89908C12.4677 9.09203 10.5222 10.5736 8 10.5736Z'
        stroke='none'
        fill='currentColor'
      />
      <Path
        d='M3.96094 5.89117C4.02501 5.89117 4.0913 5.8745 4.15096 5.84004C4.30673 5.75112 4.58734 5.61775 5.0403 5.48548C5.86666 5.24429 6.9173 5.11202 7.99887 5.11202C8.21209 5.11202 8.38554 4.93752 8.38554 4.723C8.38554 4.50849 8.21209 4.33398 7.99887 4.33398C6.84659 4.33398 5.71973 4.47737 4.82487 4.73856C4.40285 4.86194 4.04822 5.00421 3.76871 5.16315C3.58311 5.26874 3.51793 5.5066 3.62288 5.69333C3.69358 5.82004 3.82505 5.89117 3.95983 5.89117H3.96094Z'
        stroke='none'
        fill='currentColor'
      />
    </SVG>
  );
};
