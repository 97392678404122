/* eslint-disable eslint-comments/require-description */
import { useApolloClient } from '@apollo/client';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { intersection, isString, throttle } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  Provider,
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { ExperimentContext } from 'src/experimentation/ExperimentContext';
import { IExperimentContext } from 'src/experimentation/IExperimentContext';
import { usePromotionalsHook } from 'src/promotionals/usePromotionalsHook';
import { GqlClient } from 'src/shared/GqlClient';
import { useTracker } from 'src/shared/hooks/useTracker';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { useSyncUserStateWithCart } from 'src/store/cart/utils/useSyncUserStateWithCart';
import * as persistedStorage from 'src/store/storage';
import { ITracker } from 'src/tracking/types';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { cartSlice, createCartListeners, editableOrderCartSlice } from './cart';
import { clearCarts, init as cartInit, setPromoCode, setZipCode } from './cart/actions';
import { validateCartItemDeliveryDateMiddleware, validateFoodItemCapacityMiddleware } from './cart/middleware';
import { checkoutSlice } from './checkout';
import { exploreSlice } from './explore';
import { helpButtonSlice } from './help';
import { listenerMiddleware } from './listenerMiddleware';
import { loginSlice } from './login';
import { navbarSlice } from './navbar';
import { searchSlice } from './search';
import { shefProfileSlice } from './shef';

const defaultStorage = getBrowserStorage();

interface CreateStoreParams {
  gqlClient?: GqlClient;
  loadPersistedState?: typeof persistedStorage.load;
  savePersistedState?: typeof persistedStorage.save;
  experimentManager?: IExperimentContext;
  tracker?: ITracker;
  storage?: Storage;
}

const reducer = combineReducers({
  cart: cartSlice.reducer,
  checkout: checkoutSlice.reducer,
  explore: exploreSlice.reducer,
  editableOrderCart: editableOrderCartSlice.reducer,
  helpButton: helpButtonSlice.reducer,
  navbar: navbarSlice.reducer,
  search: searchSlice.reducer,
  shefProfile: shefProfileSlice.reducer,
  login: loginSlice.reducer,
});

export const createStore = ({
  gqlClient,
  tracker,
  loadPersistedState = persistedStorage.load,
  savePersistedState = persistedStorage.save,
  storage = defaultStorage,
}: CreateStoreParams) => {
  const preloadedState = loadPersistedState({ storage });
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(listenerMiddleware.middleware)
        .concat(validateCartItemDeliveryDateMiddleware, validateFoodItemCapacityMiddleware),
  });

  if (gqlClient) {
    createCartListeners({ gqlClient, cartSlice, checkoutSlice, tracker });

    // Initialize with whether in experiment to ignore next day as default delivery day.
    store.dispatch(cartInit());
  }

  const throttledSavePersistedState = throttle(() => savePersistedState({ storage, state: store.getState() }), 1000, {
    trailing: true,
  });
  store.subscribe(throttledSavePersistedState);

  return { store };
};

// Infer the RootState and AppDispatch types from the store itself
export type Store = ReturnType<typeof createStore>['store'];
export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = Store['dispatch'];

// Use throughout the app instead of plain `useDispatch` and `useSelector` get get valid TS typing
// eslint-disable-next-line functional/prefer-tacit
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const ReduxProvider: React.FC = ({ children }) => {
  const gqlClient = useApolloClient();
  const tracker = useTracker();
  const expManager = useContext(ExperimentContext);

  const { store } = useMemo(
    // typing of the useApolloClient doesn't carry over the valid typing
    // of our configured apollo client
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => createStore({ experimentManager: expManager, tracker, gqlClient }),
    // HACK: need to avoid creating multiple stores as it will duplicate the listeners
    // duplicating all async actions on each hot module reload. this can be fixed by
    // moving the store out of App.tsx and back into index.tsx after the experiement
    // manager has been fully integrated with redux store (as the experiment code is
    // firmly in the App.tsx at the moment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [, , zipCodeMetaData] = useCachedZipCode();
  const { code, removeCode } = usePromotionalsHook();
  const zipCode = zipCodeMetaData.cachedZipCodeData?.zipCode?.zipCode;
  const regionId = zipCodeMetaData.cachedZipCodeData?.zipCode?.region?.id;

  useEffect(
    () =>
      store.subscribe(async () => {
        const { cart } = store.getState();
        const { zipCode: cartZipCode, regionId: cartRegionId } = cart;
        const regionChanged = regionId && cartRegionId && regionId !== cartRegionId;
        if (zipCode && regionId && (zipCode !== cartZipCode || regionChanged)) {
          store.dispatch(setZipCode({ zipCode, regionId }));
        }

        const cartShefIds = Object.values(cart.foodItems.ids)
          .map((id) => cart.foodItems.entities[id || -1]?.userId)
          .filter(isString);

        if (code?.restrictedToShefIds && !intersection(cartShefIds, code.restrictedToShefIds)[0]) {
          removeCode(code);
          setPromoCode({ promoCode: undefined });
        }

        if (regionChanged) {
          store.dispatch(clearCarts());

          if (code?.restrictedToShefIds?.[0]) {
            removeCode(code);
            setPromoCode({ promoCode: undefined });
          }
        }
      }),
    [store, zipCode, regionId, code, removeCode]
  );

  useSyncUserStateWithCart({ store });

  return <Provider store={store} children={children} />;
};
