import { ClientEvents } from 'common/events/ClientEvents';
import { SignUpMethod } from 'common/events/ServerTypes';
import { StorageKeys } from 'common/storage/constants';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { usePromotionalsHook } from 'src/promotionals/usePromotionalsHook';
import { useTracker } from 'src/shared/hooks/useTracker';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { useGlobalReducer } from 'src/shef-global-state/shefGlobalState';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { IAuthResponse } from './IAuthResponse';

export const useHandleLogin = () => {
  const { reduce } = useGlobalReducer();
  const tracker = useTracker();
  const { code } = usePromotionalsHook();
  const [cachedZipCode, setCachedZipCode] = useCachedZipCode();

  const handleLogin = useCallback(
    (method: SignUpMethod | null, { user, token, isImpersonatedUser, isExistingUser }: IAuthResponse) => {
      const { createdAt, lastSearchedZipCode } = user;
      getBrowserStorage().setItem(StorageKeys.TOKEN, token);

      if (!isImpersonatedUser) {
        tracker.identify({
          ...user,
          lastSearchedZipCode: cachedZipCode ?? lastSearchedZipCode ?? null,
          createdAt: DateTime.fromISO(createdAt),
          lastLogin: new Date(),
        });
      }

      if (!isExistingUser) {
        tracker.track(ClientEvents.REGISTER_SUCCESS, {
          method: method ?? undefined,
          promoCode: code?.code ?? undefined,
        });
      }

      if (!cachedZipCode && lastSearchedZipCode) {
        setCachedZipCode(lastSearchedZipCode);
      }

      reduce((state) => {
        return {
          ...state,
          currentUser: user,
        };
      });
    },
    [reduce, tracker, cachedZipCode]
  );

  return handleLogin;
};
