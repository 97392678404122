import { ExperimentNames, EXP_VAR_TREATMENT } from 'common/experiments/ExperimentDefinitions';
import { useVariant } from 'src/experimentation/useVariant';

type BulkDiscountExperiment = {
  isBulkDiscountExperiment: boolean;
  logExposure: () => void;
};

export const useIsBulkDiscountExperiment = (): BulkDiscountExperiment => {
  const { variant, logExposure } = useVariant(ExperimentNames.BULK_DISCOUNT, true);
  const isVariantTreatment = variant?.name === EXP_VAR_TREATMENT;

  return {
    isBulkDiscountExperiment: isVariantTreatment,
    logExposure,
  };
};
