import { minScreen } from 'src/shared/utils/StyledComponentUtils';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { Border, Colors, Font, ScreenWidth, Transition } from 'web-common/src/shared/styles';
import { Button } from './Button';
import { ButtonColors, ButtonProps, ButtonSelectStyles } from './types';
import { createButtonVariants } from './utils';

type CreateSecondaryColorProps = {
  backgroundActive?: string;
  backgroundDisabled?: string;
  backgroundHover?: string;
  backgroundSelected?: string;
  backgroundSelectedHover?: string;
  borderColor?: string;
  borderColorDisabled?: string;
  borderColorHover?: string;
  borderColorSelected?: string;
  textColor?: string;
  textColorSelected?: string;
  disabled?: boolean;
  error?: boolean;
  isSelected?: boolean;
  selectedStyle?: ButtonSelectStyles;
};
const createSecondaryColorStyle = ({
  backgroundActive,
  backgroundDisabled,
  backgroundHover,
  backgroundSelected,
  backgroundSelectedHover,
  borderColor,
  borderColorDisabled,
  borderColorHover,
  borderColorSelected,
  textColor,
  textColorSelected,
  disabled,
  error,
  isSelected,
  selectedStyle,
}: CreateSecondaryColorProps) => css`
  color: ${isSelected ? textColorSelected : textColor};
  border: ${Border.Base} ${isSelected ? borderColorSelected : borderColor};

  ${match({ error, disabled, isSelected, selectedStyle })
    .with(
      { error: true },
      () => css`
        background: ${Colors.BELL_PEPPER_LITE_30};
        color: ${Colors.RADISH};
        border: ${Border.Base} ${Colors.RADISH};
      `
    )
    .with(
      { disabled: true },
      () => css`
        background: ${backgroundDisabled};
        border: ${Border.Base} ${borderColorDisabled};
        color: ${borderColorDisabled};
      `
    )
    .with(
      { selectedStyle: ButtonSelectStyles.BORDER },
      () => css`
        color: ${isSelected ? Colors.GRAY_DARK_30 : Colors.GRAY_DARK_10};
        border: ${Border.Base} ${isSelected ? borderColor : Colors.GRAY_DARK_10};
        background: ${Colors.WHITE};
        box-shadow: ${isSelected ? `inset 0 0 0 1px ${borderColor}` : `0 0 0 0 transparent`};
        font-weight: ${isSelected ? Font.Weight.BOLD : Font.Weight.NORMAL};

        &:hover {
          background: ${isSelected ? Colors.WHITE : backgroundHover};
          border-color: ${isSelected ? borderColor : borderColor};
          color: ${isSelected ? Colors.GRAY_DARK_30 : Colors.GRAY_DARK_20};
        }
      `
    )
    .otherwise(
      () => css`
        color: ${isSelected ? textColorSelected : textColor};
        border: ${Border.Base} ${isSelected ? borderColorSelected : borderColor};
        background: ${isSelected ? backgroundSelected : Colors.WHITE};

        ${minScreen(ScreenWidth.MEDIUM)} {
          &:hover {
            background: ${isSelected ? backgroundSelectedHover : backgroundHover};
            border-color: ${isSelected ? borderColorSelected : borderColorHover};
          }
        }
        &:active {
          background: ${backgroundActive};
          border-color: ${borderColorHover};
          color: ${textColor};
          box-shadow: ${error ? `0 0 0 0 transparent` : `inset 0 0 0 1px ${borderColorHover}`};
        }
      `
    )}
`;

export const SecondaryButtonComponent = styled(Button)<{ RED?: ButtonProps }>`
  transition: background ${Transition.Time.BASE}, border ${Transition.Time.BASE}, box-shadow ${Transition.Time.BASE},
    color ${Transition.Time.BASE}, font-weight ${Transition.Time.BASE};

  ${({ color, disabled, error, isSelected, selectedStyle }) =>
    match(color)
      .with(ButtonColors.RED, () =>
        createSecondaryColorStyle({
          backgroundActive: Colors.WHITE,
          backgroundDisabled: Colors.BELL_PEPPER_LITE_30,
          backgroundHover: Colors.BELL_PEPPER_LITE_30,
          backgroundSelected: Colors.RADISH,
          backgroundSelectedHover: Colors.BELL_PEPPER_LITE_10,
          borderColor: Colors.RADISH,
          borderColorDisabled: Colors.BELL_PEPPER_LITE_20,
          borderColorHover: Colors.BELL_PEPPER_LITE_10,
          borderColorSelected: Colors.BELL_PEPPER_DARK_10,
          disabled,
          error,
          isSelected,
          selectedStyle,
          textColor: Colors.RADISH,
          textColorSelected: Colors.WHITE,
        })
      )
      .with(ButtonColors.BLUE, () =>
        createSecondaryColorStyle({
          backgroundActive: Colors.WHITE,
          backgroundDisabled: Colors.AQUA_LITE_30,
          backgroundHover: Colors.AQUA_LITE_30,
          backgroundSelected: Colors.AQUA,
          backgroundSelectedHover: Colors.AQUA_LITE_10,
          borderColor: Colors.AQUA,
          borderColorDisabled: Colors.AQUA_LITE_20,
          borderColorHover: Colors.AQUA,
          borderColorSelected: Colors.AQUA_DARK_20,
          disabled,
          error,
          isSelected,
          selectedStyle,
          textColor: Colors.AQUA,
          textColorSelected: Colors.WHITE,
        })
      )
      .with(ButtonColors.YELLOW, () =>
        createSecondaryColorStyle({
          backgroundActive: Colors.WHITE,
          backgroundDisabled: Colors.JACKFRUIT_LITE_20,
          backgroundHover: Colors.JACKFRUIT_LITE_30,
          backgroundSelected: Colors.JACKFRUIT,
          backgroundSelectedHover: Colors.JACKFRUIT,
          borderColor: Colors.JACKFRUIT_LITE_10,
          borderColorDisabled: Colors.JACKFRUIT_LITE_10,
          borderColorHover: Colors.JACKFRUIT,
          borderColorSelected: Colors.JACKFRUIT_DARK_20,
          disabled,
          error,
          isSelected,
          selectedStyle,
          textColor: Colors.JACKFRUIT,
          textColorSelected: Colors.GRAY_DARK_30,
        })
      )
      .with(ButtonColors.LIGHT_GRAY, () =>
        createSecondaryColorStyle({
          backgroundActive: Colors.WHITE,
          backgroundDisabled: Colors.WHITE,
          backgroundHover: Colors.WHITE,
          backgroundSelected: Colors.GRAY_DARK_30,
          backgroundSelectedHover: Colors.GRAY_DARK_10,
          borderColor: Colors.GRAY_LITE_10,
          borderColorDisabled: Colors.GRAY_LITE_20,
          borderColorHover: Colors.GRAY_DARK_10,
          borderColorSelected: Colors.GRAY_DARK_30,
          disabled,
          error,
          isSelected,
          selectedStyle,
          textColor: Colors.GRAY_DARK_30,
          textColorSelected: Colors.WHITE,
        })
      )
      .otherwise(() =>
        createSecondaryColorStyle({
          backgroundActive: Colors.GRAY_LITE_30,
          backgroundDisabled: Colors.GRAY_LITE_30,
          backgroundHover: Colors.GRAY_LITE_30,
          backgroundSelected: Colors.GRAY_DARK_10,
          backgroundSelectedHover: Colors.GRAY,
          borderColor: Colors.GRAY_DARK_10,
          borderColorDisabled: Colors.GRAY_LITE_10,
          borderColorHover: Colors.GRAY_DARK_30,
          borderColorSelected: Colors.GRAY_DARK_30,
          disabled,
          error,
          isSelected,
          selectedStyle,
          textColor: Colors.GRAY_DARK_30,
          textColorSelected: Colors.WHITE,
        })
      )}
`;

export const SecondaryRedButton = styled(SecondaryButtonComponent).attrs(() => ({
  color: ButtonColors.RED,
}))``;

export const SecondaryButton = createButtonVariants(SecondaryButtonComponent);
