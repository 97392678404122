import { usePromotionalsHook } from 'src/promotionals/usePromotionalsHook';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { match, P } from 'ts-pattern';

export const useMealPlanDiscount = () => {
  const { availablePromos, code } = usePromotionalsHook();
  const [currentUser] = useCurrentUser();
  const isMealPlanDiscountEligible =
    !currentUser?.hasActiveMealPlan && (currentUser?.isMealPlanDiscountEligible ?? true);
  const referralPromo = [...availablePromos, code].find((promo) => promo?.promoCodeType === 'REFERRAL');
  const shouldUseReferralPromo =
    referralPromo != null && referralPromo?.maxUsesPerUser === referralPromo?.usesRemainingForUser;

  const discount = (shouldUseReferralPromo ? referralPromo?.value : null) ?? (isMealPlanDiscountEligible ? 30 : 0);
  const isPercentageDiscount =
    (shouldUseReferralPromo ? referralPromo?.isPercentage : null) ?? isMealPlanDiscountEligible;
  const discountText = match({ isPercentageDiscount, discount })
    .with({ isPercentageDiscount: true, discount: P.not(0) }, () => `${discount}%`)
    .with({ isPercentageDiscount: false, discount: P.not(0) }, () => `$${Math.floor(discount / 100)}`)
    .otherwise(() => '');

  const orderCount = shouldUseReferralPromo ? 2 : 1;
  const totaledDiscountText = match({ isPercentageDiscount, discount })
    .with({ isPercentageDiscount: true, discount: P.not(0) }, () => `${discount}%`)
    .with({ isPercentageDiscount: false, discount: P.not(0) }, () => `$${Math.floor((discount * orderCount) / 100)}`)
    .otherwise(() => '');
  const orderCountText = shouldUseReferralPromo ? 'first two' : 'first';
  const orderPluralityText = shouldUseReferralPromo ? 'orders' : 'order';
  const numOrdersText = `${orderCountText} meal plan ${orderPluralityText}`;
  const referralPromoText = `${discountText} off each of your ${numOrdersText}!`;
  const promoText = `Get ${discountText} off your ${numOrdersText}!`;
  const applyDiscountToPrice = (priceInCents: number): number =>
    match([isMealPlanDiscountEligible, isPercentageDiscount])
      .with([true, false], () => priceInCents - discount)
      .with([true, true], () => priceInCents * (1 - discount / 100))
      .otherwise(() => priceInCents);

  return {
    discountText,
    totaledDiscountText,
    isPercentageDiscount,
    discount,
    referralPromo,
    orderCountText,
    orderCount,
    numOrdersText,
    referralPromoText,
    promoText,
    applyDiscountToPrice,
  };
};
