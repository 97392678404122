import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Exclamation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <Path d='M13.1,1.8c0.9,0.7,1.5,1.8,1.4,2.9l-1.4,11.4c-0.1,0.5-0.5,0.9-1,0.9c-0.5,0-0.9-0.3-1.1-0.9L9.6,4.8C9.4,3.6,9.9,2.4,11,1.9C11.5,1.4,12.5,1.4,13.1,1.8z M13.8,20.8L13.8,20.8c0,0.9-0.8,1.8-1.8,1.8l0,0c-0.9,0-1.8-0.8-1.8-1.8l0,0c0-0.9,0.8-1.8,1.8-1.8l0,0C12.9,18.9,13.8,19.7,13.8,20.8z' />
    </SVG>
  );
};
