import { ShefErrorCode, ShefErrorCodeWithPayload } from 'common/errors/ShefErrors';
import { GraphQLError } from 'graphql';
import { ShefApiResponseError } from './ShefApiResponseError';

export const isShefApiResponseErrorWithCode = <C extends ShefErrorCode | ShefErrorCodeWithPayload>(
  error: GraphQLError,
  code: C
): error is ShefApiResponseError<C> => {
  return error.extensions.code === code;
};
