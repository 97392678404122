import { useMemo } from 'react';
import { useSearchLazyQuery } from 'src/gqlReactTypings.generated.d';
import { UseCombineSearchResultsQueryProps } from '../types';

export const useCombinedSearchResultsQuery = (props: UseCombineSearchResultsQueryProps) => {
  const { query, zipCode, shefPageSize, dishPageSize } = props;

  const defaultSearchInput = useMemo(() => {
    return { query, shefPageSize, dishPageSize, zipCode: zipCode ?? '' };
  }, [query, shefPageSize, dishPageSize, zipCode]);

  const [getSearch, { data, loading, error, fetchMore }] = useSearchLazyQuery({
    fetchPolicy: 'network-only',
    variables: { searchInput: defaultSearchInput },
  });

  return {
    data,
    loading,
    error,
    defaultSearchInput,
    getSearch,
    fetchMore,
  };
};
