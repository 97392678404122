import { ActiveExperiments, ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { ExperimentManager } from 'common/experiments/ExperimentManager';
import { GatedRollout } from 'common/gated-rollout/GatedRollout';
import React, { useMemo } from 'react';
import { useSessionId } from 'src/sessions/useSessionId';
import { isProdEnvironment } from 'src/shared/utils/EnvironmentUtilities';
import { useScopedReducer } from 'src/shef-global-state/shefGlobalState';
import { ITracker } from 'src/tracking/types';
import { ExperimentContext, LocalExperimentContext } from './ExperimentContext';
import { ExperimentLogger } from './ExperimentLogger';
import { useExperimentReporters } from './useExperimentReporters';
import { useVariantOverrides } from './useVariantOverrides';

interface ExperimentContextProviderProps {
  tracker: ITracker;
}

export const ExperimentContextProvider: React.FC<ExperimentContextProviderProps> = ({ children, tracker }) => {
  const { state: currentUser } = useScopedReducer('currentUser');
  const deviceId = tracker.browserTrackerId;
  const sessionId = useSessionId();

  const logger = useMemo(() => new ExperimentLogger(tracker), [tracker]);

  const [variantOverrides, setVariantOverrides] = useVariantOverrides();

  const gatedRollout = useMemo(() => new GatedRollout(), []);
  const expManager = useMemo(
    () =>
      new ExperimentManager({
        eventManager: logger,
        gatedRollout,
        experimentConfig: ActiveExperiments,
        logger: console,
        variantOverrides,
        shouldSaltWithSessionId: !isProdEnvironment(),
        userId: currentUser?.id,
        deviceId,
        sessionId,
      }),
    [currentUser?.id, gatedRollout, logger, variantOverrides, deviceId, sessionId]
  );
  const localExpCtx = useMemo(() => new LocalExperimentContext(expManager), [expManager]);

  const expContext = useMemo(
    () => ({
      logExposure: localExpCtx.logExposure.bind(localExpCtx),
      getAllExperiments: localExpCtx.getAllExperiments.bind(localExpCtx),
      getExperiment: localExpCtx.getExperiment.bind(localExpCtx),
      getVariant: localExpCtx.getVariant.bind(localExpCtx),
      setVariantOverride: (experimentName: ExperimentNames, experimentVariant: string) => {
        localExpCtx.setVariantOverride(experimentName, experimentVariant);
        // duplicates the write to local storage that localExpCtx does, but enables reactive
        // usage of overrides both here and elsewhere in the application
        setVariantOverrides(expManager.variantOverrides);
      },
    }),
    [localExpCtx]
  );

  // sync experiment statuses with third-party reports
  useExperimentReporters({
    expContext: localExpCtx,
    variantOverrides,
    gatedRollout,
    userId: currentUser?.id,
    deviceId,
  });

  return <ExperimentContext.Provider value={expContext}>{children}</ExperimentContext.Provider>;
};
