import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { isProdEnvironment } from '../utils/EnvironmentUtilities';
import { ErrorFallback } from './ErrorFallback';

export const withErrorBoundary =
  <T,>(WrappedComponent: React.ComponentType<T>): React.FC<T> =>
  (props: T) => {
    if (isProdEnvironment()) {
      return (
        <ErrorBoundary fallback={ErrorFallback}>
          <WrappedComponent {...props} />
        </ErrorBoundary>
      );
    }

    return <WrappedComponent {...props} />;
  };
