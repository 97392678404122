import { createSelector } from '@reduxjs/toolkit';
import { selectShefAvailableLineItemQuantity } from 'src/store/cart/selectors/lineItems';
import { selectCartShefById } from 'src/store/cart/selectors/shefs';
import { CartState } from 'src/store/cart/types';
import { getAdjustedNumDishesAvailable, getShefDeliveryDateCapacityInfo } from 'src/store/cart/utils/capacity';

export const selectShefDeliveryDateCapacityInfo = createSelector(
  selectCartShefById,
  selectShefAvailableLineItemQuantity,
  (_state: CartState, _shefId: string, _isEditingOrder: boolean, deliveryDate: string) => deliveryDate,
  (state: CartState) => state.rawOrderData,
  getShefDeliveryDateCapacityInfo
);

export const selectShefAvailabilityWithCapacityForLineItems = createSelector(
  selectShefAvailableLineItemQuantity,
  selectCartShefById,
  (state: CartState) => state.rawOrderData,
  (shefLineItemCount, shef, rawOrderData) => {
    if (!shef || shef.loading) return undefined;
    return new Set(
      shef.availability
        .filter((day) => {
          const numDishesAvailable = getAdjustedNumDishesAvailable({
            shefAvailability: shef.availability,
            deliveryDate: day.availabilityDate,
            rawOrderData,
          });
          const numDishesRemaining = numDishesAvailable - shefLineItemCount ?? 0;
          return numDishesRemaining > 0;
        })
        .map((day) => day.availabilityDate)
    );
  }
);
