import { StorageKeys } from 'common/storage/constants';
import React, { useCallback, useMemo } from 'react';
import { CelebrityEventType } from 'src/gqlReactTypings.generated.d';
import { useLocalStorage } from 'src/shared/storage/Storage';

/**
 * Currently logic for one-off campaigns such as Binging with Babish cookies and Master Chef Nyesha.
 * As we run more of these campaigns, we'll formalize a lot of this into a Model and retool interface
 */

interface IProps {
  isOutsideCelebrityEventMarket: boolean;
  setIsOutsideCelebrityEventMarket: (boolean) => void;

  celebrityEventType: CelebrityEventType | null;
  setCelebrityEventType: (type: CelebrityEventType) => void;
  clearCelebrityEventType: () => void;
}

export const CelebrityEventContext = React.createContext<IProps>({
  isOutsideCelebrityEventMarket: false,
  setIsOutsideCelebrityEventMarket: () => undefined,

  celebrityEventType: null,
  setCelebrityEventType: () => undefined,
  clearCelebrityEventType: () => undefined,
});

export const CelebrityEventProvider: React.FC = ({ children }) => {
  const showSecondaryMarketExplanationStorageKey = `${StorageKeys.CELEBRITY_EVENT}-showSecondaryMarketExplanation`;
  const [isOutsideCelebrityEventMarket, setIsOutsideCelebrityEventMarket] = useLocalStorage<boolean>(
    showSecondaryMarketExplanationStorageKey,
    false
  );

  const celebrityEventTypeStorageKey = `${StorageKeys.CELEBRITY_EVENT}-celebrityEventTypeStorageKey`;
  const [celebrityEventType, setCelebrityEventType] = useLocalStorage<CelebrityEventType | null>(
    celebrityEventTypeStorageKey,
    null
  );
  const clearCelebrityEventType = useCallback(() => {
    setCelebrityEventType(null);
    setIsOutsideCelebrityEventMarket(false);
  }, [setCelebrityEventType, setIsOutsideCelebrityEventMarket]);

  // Downstream components depend on this being the same object if possible
  const value = useMemo(
    (): IProps => ({
      isOutsideCelebrityEventMarket,
      setIsOutsideCelebrityEventMarket,
      celebrityEventType,
      setCelebrityEventType,
      clearCelebrityEventType,
    }),
    [
      isOutsideCelebrityEventMarket,
      setIsOutsideCelebrityEventMarket,
      celebrityEventType,
      setCelebrityEventType,
      clearCelebrityEventType,
    ]
  );

  return <CelebrityEventContext.Provider value={value}>{children}</CelebrityEventContext.Provider>;
};
