import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Colors, FontSize, FontWeight, Spacing } from 'web-common/src/shared/styles';

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;

  gap: ${Spacing.HALF};

  border: none;
`;

const LegendContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${Spacing.HALF};

  opacity: ${ifProp('disabled', 0.5)};
`;

const StyledLegend = styled.legend`
  flex-grow: 1;
  font-size: ${FontSize.M};
  font-weight: ${FontWeight.BOLD};
  color: ${Colors.GRAY_DARK_30};
  border: none;

  margin: 0;
`;

export enum FieldsetTag {
  REQUIRED = 'Required',
  OPTIONAL = 'Optional',
}

const Tag = styled.span<{ type: FieldsetTag.OPTIONAL | FieldsetTag.REQUIRED }>`
  flex-grow: 0;
  padding: ${Spacing.QUARTER} ${Spacing.HALF} ${Spacing.QUARTER} ${Spacing.HALF};
  border-radius: ${Spacing.QUARTER};

  font-size: ${FontSize.S};
  font-weight: ${FontWeight.MEDIUM};

  color: ${({ type }) => (type === FieldsetTag.OPTIONAL ? Colors.GRAY_DARK_15 : Colors.WHITE)};
  background: ${({ type }) => (type === FieldsetTag.OPTIONAL ? Colors.GRAY_LITE_20 : Colors.GRAY_DARK_30)};
`;

type FieldsetProps = { label: string; className?: string; disabled?: boolean; tag?: FieldsetTag };

export const Fieldset: React.FC<React.PropsWithChildren<FieldsetProps>> = ({
  children,
  label,
  className,
  disabled,
  tag,
}) => {
  return (
    <StyledFieldset className={className} disabled={disabled}>
      <LegendContainer disabled={disabled}>
        <StyledLegend>{label}</StyledLegend>
        {!!tag && <Tag type={tag}>{tag}</Tag>}
      </LegendContainer>
      {children}
    </StyledFieldset>
  );
};
