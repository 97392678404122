import styled from 'styled-components';
import { AvatarImage } from './AvatarImage';

interface AvatarImageGroupProps {
  size: number;
  imageUrls: string[];
}

const StyledAvatarImage = styled(AvatarImage)<{ zIndex: number }>`
  z-index: ${({ zIndex }) => zIndex};
`;

const StyledAvatarImageGroupContainer = styled.div<{ avatarSize: number }>`
  display: flex;

  ${StyledAvatarImage} {
    margin-left: -${({ avatarSize }) => avatarSize / 2}px;
    mask: radial-gradient(circle at left, transparent 50%, gray 50.5%);
  }

  ${StyledAvatarImage}:first-child {
    margin-left: 0;
    mask: none;
  }
`;

export const AvatarImageGroup = ({ size, imageUrls }: AvatarImageGroupProps): JSX.Element => {
  return (
    <StyledAvatarImageGroupContainer avatarSize={size}>
      {imageUrls.map((imageUrl, index) => (
        <StyledAvatarImage size={`${size}px`} imageUrl={imageUrl} key={index} zIndex={imageUrls.length - index} />
      ))}
    </StyledAvatarImageGroupContainer>
  );
};
