import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Close = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <Path d='M21.4,18.6c0.8,0.8,0.8,2,0,2.8C21,21.8,20.5,22,20,22s-1-0.2-1.4-0.6L12,14.8l-6.6,6.6C5,21.8,4.5,22,4,22s-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.8L9.2,12L2.6,5.4c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0L12,9.2l6.6-6.6c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8L14.8,12L21.4,18.6z' />
    </SVG>
  );
};

export const CloseV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.7188 12.4486C14.0563 12.8224 14.0563 13.3832 13.7188 13.7196C13.3438 14.0935 12.7812 14.0935 12.4438 13.7196L8.01875 9.27103L3.55625 13.7196C3.18125 14.0935 2.61875 14.0935 2.28125 13.7196C1.90625 13.3832 1.90625 12.8224 2.28125 12.4486L6.74375 8L2.28125 3.5514C1.90625 3.17757 1.90625 2.61682 2.28125 2.28037C2.61875 1.90654 3.18125 1.90654 3.51875 2.28037L8.01875 6.76636L12.4813 2.31776C12.8188 1.94393 13.3813 1.94393 13.7188 2.31776C14.0938 2.65421 14.0938 3.21495 13.7188 3.58879L9.25625 8L13.7188 12.4486Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const CloseSmall = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={16} width={16} viewBox={'0 0 16 16'} {...props}>
      <Path d='M13.7188 12.4486C14.0563 12.8224 14.0563 13.3832 13.7188 13.7196C13.3438 14.0935 12.7812 14.0935 12.4438 13.7196L8.01875 9.27103L3.55625 13.7196C3.18125 14.0935 2.61875 14.0935 2.28125 13.7196C1.90625 13.3832 1.90625 12.8224 2.28125 12.4486L6.74375 8L2.28125 3.5514C1.90625 3.17757 1.90625 2.61682 2.28125 2.28037C2.61875 1.90654 3.18125 1.90654 3.51875 2.28037L8.01875 6.76636L12.4813 2.31776C12.8188 1.94393 13.3813 1.94393 13.7188 2.31776C14.0938 2.65421 14.0938 3.21495 13.7188 3.58879L9.25625 8L13.7188 12.4486Z' />
    </SVG>
  );
};
