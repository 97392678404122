import { ReferralShareLocation } from 'common/events/ClientEvents';
import { formatCurrency } from 'common/FormatUtilities';
import { useCallback } from 'react';
import ReferralGiftBox from 'src/assets/svg/referral-gift-box.svg';
import { CurrentUserFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { ReferralLink } from 'src/pages/consumer/account/refer/ReferralLink';
import { useTrackShare } from 'src/pages/consumer/account/refer/useTrackShare';
import { DishFirstModalFooter, DishFirstModalSecondaryButton } from 'src/pages/consumer/dish-first/DishFirstModal';
import { ButtonSizes, PrimaryButton } from 'src/shared/design-system/Buttons';
import { Header2 } from 'src/shared/design-system/Headers';
import { useHistory } from 'src/shared/hooks/useHistory';
import { useShare } from 'src/shared/hooks/useShare';
import Notifications from 'src/shared/Notifications';
import { Routes } from 'src/shared/Routes';
import { getReferralUrl } from 'src/shared/utils/PromoUtilities';
import styled from 'styled-components';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { Colors } from 'web-common/src/shared/styles';

const GiftBoxImage = styled.img.attrs(() => ({ src: ReferralGiftBox }))`
  width: 150px;
  height: 150px;
  object-fit: contain;
`;

const LearnMore = styled.span`
  color: ${Colors.GRAY_DARK_15};

  &:hover {
    color: ${Colors.KOHLRABI};
  }
`;

interface ConsumerGroupOrderCommentModalCommentStepProps {
  currentUser: Pick<CurrentUserFieldsFragment, 'referralCode'>;
  close: () => void;
}

export const ConsumerGroupOrderCommentModalReferralStep = ({
  currentUser,
  close,
}: ConsumerGroupOrderCommentModalCommentStepProps): JSX.Element => {
  const { referralCode } = currentUser;
  const { code, giveValue, getValue } = referralCode;

  const { isSmallScreen } = useScreenContext();
  const url = getReferralUrl({ code });

  const history = useHistory();
  const learnMore = useCallback(() => {
    close();
    history.push(Routes.CONSUMER_ACCOUNT_SHARE);
  }, [history, close]);

  const trackShare = useTrackShare({ code, location: ReferralShareLocation.AFTER_5_STAR_REVIEW });
  const { share } = useShare({
    url,
    onShare: (type) => {
      trackShare(type);
      if (type === 'copy') {
        Notifications.success('Copied link!');
      }
    },
    onError: () => {
      Notifications.error('Failed to copy code');
    },
  });

  const formattedGetValue = formatCurrency(getValue, true);
  const giveUsesPerUser = referralCode?.maxUsesPerUser ?? 0;
  const formattedGiveValue = formatCurrency(giveValue * giveUsesPerUser, true);

  return (
    <>
      <div className='flex grow flex-col items-center px-4 pt-[5vh] sm:px-6 sm:pt-0'>
        <GiftBoxImage className='mb-8' />
        <Header2>You loved it, now share it!</Header2>
        <span className='my-4 text-[16px]'>
          Tap to give {formattedGiveValue} off to a friend and get {formattedGetValue} off your next order.{' '}
          <LearnMore className='mb-6 ml-1 w-fit cursor-pointer' onClick={learnMore}>
            Learn More
          </LearnMore>
        </span>
        <ReferralLink url={url} code={code} location={ReferralShareLocation.AFTER_5_STAR_REVIEW} />
      </div>
      <DishFirstModalFooter noShadow={!isSmallScreen}>
        <DishFirstModalSecondaryButton onClick={close}>Close</DishFirstModalSecondaryButton>
        <PrimaryButton size={ButtonSizes.L} onClick={share}>
          Share
        </PrimaryButton>
      </DishFirstModalFooter>
    </>
  );
};
