import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Serving2Portions = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <title>Serving size of 2</title>
      <desc>Two bowls, stacked</desc>
      <Path
        d='M14.4174 5.95294C14.3983 5.90439 14.3707 5.86262 14.336 5.82536C14.4222 5.01467 14.3492 4.14978 14.1098 3.33119V3.32893C13.6443 1.73804 10.1651 1.7211 7.12751 2.5126C5.51537 2.93262 4.05763 3.55362 3.02596 4.26043C2.07209 4.91305 1.55864 5.59728 1.53471 6.22618C1.51197 6.27473 1.5 6.3278 1.5 6.38313C1.5 8.08015 2.1798 9.61346 3.41374 10.6997C4.02651 11.2394 4.75539 11.6526 5.55846 11.9281V13.6048C5.55846 13.8227 5.74636 14 5.97735 14H10.0215C10.2524 14 10.4403 13.8227 10.4403 13.6048V11.9281C11.2434 11.6526 11.9723 11.2394 12.5851 10.6997C13.819 9.61346 14.4988 8.08015 14.4988 6.38313C14.4988 6.2386 14.4713 6.09408 14.4162 5.95294H14.4174ZM13.5306 4.93902C13.5605 5.68648 13.442 6.41361 13.1823 7.02897C12.3637 7.60029 10.5361 8.13322 8 8.13322C6.99466 8.13322 6.10182 8.04967 5.33705 7.91305C6.36632 7.86788 7.49135 7.69062 8.56131 7.41173C10.1734 6.99171 11.6312 6.37071 12.6629 5.6639C13.0123 5.42453 13.302 5.18177 13.5306 4.93789V4.93902ZM3.51666 4.90063C4.46934 4.24801 5.83134 3.67105 7.35012 3.27586C8.68938 2.92697 9.86826 2.78471 10.8173 2.78471C12.2655 2.78471 13.1775 3.1144 13.302 3.54007C13.4097 3.9059 12.9764 4.47496 12.171 5.02483C11.2183 5.67744 9.85629 6.25441 8.33751 6.64959C4.95645 7.53142 2.59151 7.08994 2.38566 6.38539C2.27794 6.01956 2.71119 5.4505 3.51666 4.90063ZM9.60256 13.2096H6.39624V12.1607C6.91088 12.2702 7.44826 12.3255 7.9988 12.3255C8.54935 12.3255 9.08672 12.2691 9.60136 12.1607V13.2096H9.60256ZM8 11.5352C5.26643 11.5352 3.16001 10.0312 2.53167 7.8024C3.7656 8.53744 5.92589 8.92359 8 8.92359C10.0741 8.92359 12.2344 8.53744 13.4683 7.8024C12.84 10.0301 10.7324 11.5352 8 11.5352Z'
        stroke='none'
        fill='currentColor'
      />
      <Path
        d='M3.6268 6.45755C3.73451 6.45755 3.84103 6.41916 3.92241 6.34125C4.06005 6.21254 4.31378 6.00478 4.74943 5.75187C5.54293 5.2912 6.60212 4.87456 7.73193 4.581C7.95454 4.52228 8.0862 4.3055 8.02396 4.09436C7.96292 3.88435 7.73193 3.76127 7.50813 3.81886C6.30531 4.13275 5.16951 4.57987 4.31019 5.07893C3.90446 5.31378 3.57533 5.55089 3.32879 5.78235C3.16482 5.93704 3.16482 6.18657 3.32879 6.34125C3.41017 6.41803 3.51788 6.45755 3.6244 6.45755H3.6268Z'
        stroke='none'
        fill='currentColor'
      />
    </SVG>
  );
};
