import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const PlusV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG height={16} width={16} viewBox={'0 0 16 16'} {...props}>
      <Path
        d={
          'M16,7.96154a.91116.91116,0,0,1-.9231.92308H8.92308V15.0385A.9445.9445,0,0,1,8,16a.92057.92057,0,0,1-.92308-.9615V8.88462H.92308A.88.88,0,0,1,0,8a.92057.92057,0,0,1,.92308-.96154H7.07692V.88462A.88.88,0,0,1,8,0a.90326.90326,0,0,1,.92308.88462V7.03846H15.0769A.936.936,0,0,1,16,7.96154Z'
        }
      />
    </SVG>
  );
};
