import { useExitIntent } from 'use-exit-intent';

export enum ExitIntentKey {
  FIRST_TIME_USER_ON_CHECKOUT = 'first-time-user-on-checkout',
  FIRST_TIME_USER_SIGNUP_MODAL = 'first-time-user-signup-modal',
}

interface UseShefExitIntentParams {
  key: ExitIntentKey;
  daysToExpire?: number;
}

export const useShefExitIntent = ({ key, daysToExpire = 7 }: UseShefExitIntentParams) => {
  return useExitIntent({
    cookie: {
      key,
      daysToExpire,
    },
    desktop: {
      triggerOnMouseLeave: true,
    },
    mobile: {
      triggerOnIdle: true,
      delayInSecondsToTrigger: 30,
    },
  });
};

export const useSignupModalExitIntent = () => {
  return useShefExitIntent({
    key: ExitIntentKey.FIRST_TIME_USER_SIGNUP_MODAL,
    daysToExpire: 1,
  });
};

export const useCheckoutExitIntent = () => {
  return useShefExitIntent({
    key: ExitIntentKey.FIRST_TIME_USER_ON_CHECKOUT,
    daysToExpire: 1,
  });
};
