import React from 'react';
import whiskersLeft from 'src/assets/img/celebrity-event/whiskers-left.svg';
import whiskersRight from 'src/assets/img/celebrity-event/whiskers-right.svg';
import { DynamicImage } from 'src/shared/design-system/DynamicImage';
import styled from 'styled-components';
import { BorderRadius, Colors } from 'web-common/src/shared/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100px;
  width: calc(100% - 48px); // HACK - keep width correct
`;

const BackgroundImage = styled(DynamicImage)`
  height: 96px;
  border-radius: ${BorderRadius.M};
  object-fit: cover;
  object-position: left;
`;

const CtaBanner = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: ${Colors.KOHLRABI_LITE_20};
  border-radius: 0 0 ${BorderRadius.M} ${BorderRadius.M};
  width: 100%;
  height: 30px;
`;

interface IProps {
  className?: string;
  backgroundImage: string;
  logos: string;
  ctaText: string;
}

export const BabishStyleLoginPromoBanner: React.FC<IProps> = ({ className, backgroundImage, logos, ctaText }) => {
  return (
    <Container className={className}>
      <BackgroundImage src={backgroundImage} sourceWidth={448} sourceHeight={96} />
      {/* TODO: Gil Note: Probably need to fine tune absolute positioning for multiple logos */}
      <img src={logos} className='absolute left-[26px] top-[15px]' />
      <CtaBanner>
        <img src={whiskersLeft} />
        <span className='text-[11px] font-semibold text-neutral-800'>{ctaText}</span>
        <img src={whiskersRight} />
      </CtaBanner>
    </Container>
  );
};
