import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ScreenWidth, ScreenWidthValues } from '../../styles';
import { ScreenContext } from './context';

/**
 * Iterates over ScreenWidth values from small to large, looking for the nearest screen size
 * that is larger than the current dimensions. E.g. if the screen is 2300 px wide it will return 2400px
 * */
const getNearestScreenWidth = () => {
  const sortedScreenWidth = Object.values(ScreenWidth).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

  for (const sz of sortedScreenWidth) {
    const match = window.matchMedia(`(max-width: ${sz})`).matches;
    if (match) {
      // match==true means it is smaller than SZ
      return sz; // Always return 1 size larger
    }
  }
  return ScreenWidth.EXTRA_LARGE;
};

export interface IWindowDimension {
  width: number;
  height: number;
}

const SMALL_SCREEN_WIDTH = parseInt(ScreenWidth.SMALL.replace('px', ''), 10);

const MEDIUM_SCREEN_WIDTH = parseInt(ScreenWidth.MEDIUM.replace('px', ''), 10);

function getWindowDimensions(): IWindowDimension {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export const ScreenContextProvider: React.FC = ({ children }) => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(getWindowDimensions().width <= SMALL_SCREEN_WIDTH);
  const [isMediumScreen, setIsMediumScreen] = useState<boolean>(
    getWindowDimensions().width <= MEDIUM_SCREEN_WIDTH && getWindowDimensions().width > SMALL_SCREEN_WIDTH
  );
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(getWindowDimensions().width > MEDIUM_SCREEN_WIDTH);
  const [nearestScreenWidth, setNearestScreenWidth] = useState<ScreenWidth>(getNearestScreenWidth());
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = debounce(() => {
      const newWindowDimensions = getWindowDimensions();
      const isNowLargeScreen = newWindowDimensions.width > MEDIUM_SCREEN_WIDTH;
      setIsLargeScreen(isNowLargeScreen);
      const isNowMediumScreen =
        newWindowDimensions.width <= MEDIUM_SCREEN_WIDTH && newWindowDimensions.width > SMALL_SCREEN_WIDTH;
      setIsMediumScreen(isNowMediumScreen);
      const isNowSmallScreen = newWindowDimensions.width <= SMALL_SCREEN_WIDTH;
      setIsSmallScreen(isNowSmallScreen);
      setNearestScreenWidth(getNearestScreenWidth());
      setWindowDimensions(newWindowDimensions);
    }, 100);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions, nearestScreenWidth]);

  const value = useMemo(
    () => ({
      isSmallScreen,
      isMediumScreen,
      isLargeScreen,
      nearestScreenWidth,
      nearestScreenWidthValue: ScreenWidthValues[nearestScreenWidth],
      windowDimensions,
    }),
    [isSmallScreen, isMediumScreen, isLargeScreen, nearestScreenWidth, windowDimensions]
  );

  return <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider>;
};
